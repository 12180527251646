:root {
  --nhd-border: 1px solid #aaaaaa;
  --nhd-border-radius: 0.4rem;
  --nhd-success: #dff0d8;
  --nhd-selected: #97b9cc;
  --nhd-tile: #cbe4f2;
  --nhd-finals: #f3cd7c;
  --nhd-alt-row: #f3f3f3;
}

.nhd-schedule-round {
}

.nhd-schedule-round h3,
.nhd-schedule-round h2,
.nhd-schedule-round h4,
.nhd-schedule-round h5 {
  margin-bottom: 0;
  margin-top: 0.1rem;
}

.nhd-schedule-round hr {
  margin: 0.3rem 0;
}

.nhd-schedule-round .scheduled-round-list {
}

.nhd-schedule-round .schedule-template {
  border: var(--nhd-border);
  border-radius: var(--nhd-border-radius);
  padding: 0.5rem;
  margin: 0.5rem;
}

.nhd-schedule-round .scheduled-round {
  border: var(--nhd-border);
  border-radius: var(--nhd-border-radius);
  width: 250px;
  padding: 0.5rem;
  margin: 0.5rem;
}

table.table.table-striped.tr:nth-child(even) {
  background-color: #eeeeee;
}

.nhd-round-room-slots-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.nhd-schedule-slot {
  border: var(--nhd-border);
  border-radius: var(--nhd-border-radius);
  padding: 0.5rem;
  margin: 0.5rem;
  height: 125px;
  position: relative;
  color: #000000;
  background-color: #eeeeee;
}

.nhd-schedule-slot .slot-tag {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  
  z-index: 0;
  font-size: .8rem;
  background-color: rgba(33, 33, 33, 0.3);
  color: #ffffff;
  text-align: center;
}

.nhd-round-room-slots-list .nhd-schedule-slot {
  border: var(--nhd-border);
  border-radius: var(--nhd-border-radius);
  padding: 0.5rem;
  padding-left: 1rem;
  margin: 0.5rem;
  width: max(calc(25% - 1rem), 170px);
  height: 125px;
  position: relative;
}
.nhd-schedule-slot.has-entry {
  background-color: var(--nhd-success);
  border-color: var(--nhd-success);
}

.nhd-schedule-slot.selected {
  background-color: var(--nhd-selected);
}
.nhd-schedule-slot .slot-footer {
  position: absolute;
  bottom: 0.3rem;
  left: 0.3rem;
  right: 0.3rem;
  font-size: smaller;
  color: #666666;
  text-align: left;
  cursor: pointer;
}

.nhd-schedule-slot {
  overflow: hidden;
  text-overflow: ellipsis;
  max-lines: 2;
}

.nhd-bottom-margin {
  height: 4rem;
}

.nhd-round-details-footer {
  /* position: absolute;
    bottom: .5rem;
    left:1rem;
    right:1rem; */
}

.scheduled-template-round-list {
}

.scheduled-template-round-list .scheduled-round {
}

.scheduled-template-round-list .scheduled-round {
  background-color: var(--nhd-tile);
  border-color: var(--nhd-tile);
}

.scheduled-template-round-list .scheduled-round.nhd-finals {
  background-color: var(--nhd-finals);
  border-color: var(--nhd-finals);
}

.nhd-admin-panel-categories-list {
}

.nhd-admin-panel-rounds-list {
  padding: 0.5rem;
  margin: 0.5rem 0;
  /* border: var(--nhd-border);
    border-radius: var(--nhd-border-radius); */
}

.nhd-admin-panel-rounds {
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: var(--nhd-border);
  border-radius: var(--nhd-border-radius);
}

.nhd-admin-panel-rounds:nth-child(even) {
  background-color: var(--nhd-alt-row);
}

.nhd-admin-panel-rounds.finals {
  border-color: var(--nhd-finals);
  border-left: 10px solid var(--nhd-finals);
}

.nhd-finals {
  color: var(--nhd-finals);
}

.nhd-admin-panel-rounds h5 {
  font-weight: bold;
}

.nhd-admin-panel-rooms-list .details {
  font-size: 0.8rem;
}

.nhd-admin-panel-rounds .table {
}

.nhd-admin-panel-rounds .table.table-striped tr:nth-child(odd) {
  background-color: var(--nhd-alt-row);
}
.nhd-admin-panel-rounds .table.table-striped tr:nth-child(even) {
  background-color: #ffffff;
}

.nhd-tb-25 {
  width: 40px;
}
.nhd-tb-60 {
  text-align: center;
  width: 130px;
}

.nhd-admin-panel-category {
  border: 1px solid #555555;
  border-radius: var(--nhd-border-radius);
  margin: 1.5rem 0;
}
.nhd-admin-panel-category .header {
  background-color: #555555;
  color: #dddddd;
  padding: 0.3rem;
}
