@import-normalize;

h1,
h2,
h3 {
  margin-top: 0.2em !important;
  margin-bottom: 0.4em !important;
}

html,
body {
  font-size: 14px !important;
}

body .app-main-body {
  padding: 0 1rem;
}

div.white{
  background-color: white;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-between.center {
  align-items: center;
}
.flex-between.stretch{
  align-items: stretch;
}

.wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.icon-status{
  font-size:2.5rem;
  padding: .5rem;
  width: 4rem;
  text-align: center;
}

.form-input input,
.form-input select,
.form-input.button button {
  padding: 0.3em 1em;
  border-radius: 0.25em;
  font-size: 1em;
  border: solid 2px #eeeeee;
}

.form-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
}

.form-input .input-selectable{
  min-width: min(calc(100vw - 4rem), 350px);
}

.input-remaining{
  position: absolute;
  right:1.5rem;
  top:.10rem;
  font-size: .75rem;
  color: #aaaaaa;
  cursor: default;
}

.form-input.button {
  margin-bottom: 1em;
  display: flex;
  justify-content: flex-end;
}

.form-input.button button:hover {
  box-shadow: 2px 2px 5px #aaa;
}

.form-input label {
  margin-right: 1em;
  font-size: 1em;
  font-weight: 600;
  flex-grow: 1;
}

.backdrop {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: -10;
}

.app-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: #fdfdfd;
}

.app-container .sidebar {
  position: absolute;
  width: 200px;
  top: 0;
  bottom: 0;
  left: 0;
}

.app-container .main {
  position: absolute;
  top: 0;
  bottom: 50px;
  left: 200px;
  right: 0;
  overflow: auto;
}

.app-container .footer {
  position: absolute;
  height: 50px;
  left: 200px;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.sticky {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 900;
}

.footer .app-logo {
  margin-top: -.70rem;
}

.logo {
  align-items: center;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.sidebar .logo {
  padding: 15px;
}

.footer .logo {
  padding: 4px 1em;
}

.footer img {
  position: absolute;
}

.logo img {
  max-width: 170px;
  max-height: 170px;
}

img.logo {
  max-width: 100px;
  overflow-y: visible;
}

.error {
  padding: 1em;
  color: #ffffff;
  background-color: #f56c6c;
  border: solid 1px #d56c6c;
  border-radius: 0.2em;
  margin: 0.5em 0;
}

.error.dark-text,
.error .dark-text {
  color: #333;
}

/*            Showcase              */
.showcase-gate {
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
}

.showcase-gate .gate {
  border-radius: 0.2em;
  border: solid 1px #ddd;
  padding: 2em;
  margin: 1em;
  width: 400px;
}
.gate {
  box-shadow: 2px 2px 5px #ddd;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.no-wrap {
  white-space: nowrap;
}
.stock-image {
  filter: grayscale(80%);
}
.showcase {
  padding: 0 1em;
  margin-bottom: 100px;
}

.showcase-project-2 {
  cursor: pointer;
  border: solid 1px #ddd;
  width: 271px;
  height: 275px;
  margin: 0.5em;
  /* border-radius: 4px; */
  transition: ease-in-out 0.5s;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 4px #aaa;
  border-radius: 1rem;
}
.showcase-project-2:hover {
  box-shadow: 2px 2px 4px #444;
}

.showcase-project-2 .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 5px;
  margin: 0 -1px;
  /* border-top-left-radius: 4px;
  border-top-right-radius: 4px; */
  font-size: 1.2em;
  background-color: #dddddd;
  border-bottom: solid 1px #aaaaaa;
}
.showcase-project-2 .header .ellipsis {
  font-size: 0.8em;
}
.showcase-project-2 .footer {
  display: flex;
  height: 23px;
  justify-content: space-between;
  align-items: center;
  padding: 4px 5px;
  margin: 0 -1px;
  margin-bottom: -1px;
  /* border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; */
  font-size: 1.2em;
  background-color: #dddddd;
  border-top: solid 1px #aaaaaa;
}

.showcase-project-2 .body {
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
  display: flex;
  margin-right: -1px;
  margin-left: -1px;
  flex-direction: column;
}

.showcase-project-2 .body .tile-image {
  height: 150px;
  overflow: hidden;
  position: relative;
  background-color: #888;
}
.showcase-project-2 .body .tile-image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}
.showcase-project-2 .body .tile-image img.stock-image {
  object-fit: fill;
}
.showcase-project-2 .body .tile-image img.img-back {
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  filter: blur(1rem);
  /* filter:opacity(.6); */
}
.showcase-project-2 .body .title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 1.2em;
  text-align: center;
  line-height: 1em;
  height: 2.2em;
  padding: 0.1em 0.2em;
}

.showcase-project-2 .body .owners {
  font-size: 0.8em;
  color: #777777;
  width: 100%;
  height: 1.25em;
  display: flex;
  justify-content: flex-start;
  flex-wrap: 1;
  /* position: absolute;
  background-color: #333333;
  left:-01em;
  right:0; */
}
.showcase-project-2 .body .owners span {
  margin-right: 1em;
}

.showcase-project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.showcase-search-filters {
  width: max(30%, 350px);
}

.screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(80, 80, 80, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.screen.align-start {
  align-items: flex-start;
}

.head-room {
  margin-top: 2rem;
}

.head-room-xl {
  margin-top: 64px;
}

.editor-class {
  min-height: 400px;
  max-height: min(calc(100vh - 300px), 780px);
  border: 1px solid #eee;
  border-radius: 3px;
  margin-bottom: 1em;
  padding: 0 1em;
}

.head-room-l {
  margin-top: 32px;
}

.modal-close {
  position: absolute;
  right: 0.3em;
  top: 0.3em;
}

span.close {
  border-radius: 99px;
  border: solid 1px #aaa;
  padding: 4px 8px;
  background-color: #eee;
}

span.close:hover {
  cursor: pointer;
  border-width: 2px;
  box-shadow: 2px 2px 5px #aaa;
}

.owners {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 1em;
  max-width: 275px;
}


@media (max-width: 1300px) {
  .flex-between {
    flex-wrap: wrap;
  }
}

@media (max-width: 776px) {
  .flex-between {
    flex-wrap: wrap;
  }

  .title-bar h1 {
    font-size: 1.8rem;
  }
  .title-bar .logo img {
    max-height: 35px !important;
  }
}
@media (max-width: 576px) {
  .app-container .sidebar,
  .app-container .main,
  .app-container .footer {
    position: relative;
  }
  .app-container .sidebar {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .app-container {
    overflow: auto;
  }

  .app-container .main {
    /* height:100vh; */
    bottom: inherit;
    left: inherit;
    top: inherit;
    right: inherit;
    overflow-y: auto;
  }
  .app-container .footer {
    position: relative;
    left: inherit;
    justify-content: center;
  }

  .form-input {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .small-hide {
    display: none;
  }

  .logo img {
    max-height: 75px;
  }
}
/*
.app-container .sidebar{
  position: absolute;  width:200px;  top:0;   bottom:0;  left:0;
}

.app-container .main{
  position: absolute;  top:0;  bottom:50px;  left:200px;
  right:0;
  overflow: auto;
}

.app-container .footer{
  position: absolute;
  height:50px;
  left:200px;
  bottom:0;
  right:0;
  display: flex;
  justify-content: flex-end;
}

*/

.title-bar {
  display: flex;
  justify-content: space-between;
  padding: 0.4rem;
  position: relative;
  align-items: center;
}
.title-bar .logo img {
  max-height: 55px;
}

.title-bar .logo.nhd-logo img {
  height: 55px;
  text-align: center;
}

h1,
h2,
h3 {
  margin-top: 5px;
  margin-bottom: 5px;  
}
.footer {
  display: flex;
  justify-content: space-between;
  align-content: center;
  color: #fff;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0.7rem;
}

.footer img {
  max-height: 40px;
}

.container {
  background-color: #fdfdfd;
  min-height: 90vh;
  margin-bottom: 50px;
  position: relative;
  padding-bottom: 60px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

i.fa,
i.fad,
i.fal,
i.fas,
i.far {
  padding-left: 0.3em;
  padding-right: 0.3em;
}

.navbar i.fa,
.navbar i.fad,
.navbar i.fal,
.navbar i.fas,
.navbar i.far {
  padding: 0;
}

.navbar-nav li {
  margin-right: 1rem;
}

.navbar-collapse {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.paperwork-container {
  margin: 0 1em;
}

.file-inspection {
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.file-inspection .file-inspection-header {
}

.file-inspection .file-inspection-body {
  flex-grow: 1;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
}

.file-inspection .file-inspection-body .review-file-admin-panel {
  overflow-y: auto;
  overflow-x: hidden;
}

body input[type='checkbox'] {
  margin-left: 3px;
  margin-right: 10px;
  margin-top: -7px;
  transform: scale(1.5);
  min-width: 20px;
  min-height: 13px;
  /* vertical-align: top; */
}

.inline-label .control-label {
  display: inline;
}

button.btn .caret {
  margin-left: 0.5em;
}

.btn {
  margin-right: 1em;
}

.btn-group .btn {
  margin-right: 0;
}

.editor {
  margin-right: 1em;
}

.csv-file-preview {
  max-height: 400px;
  overflow: auto;
  padding: 1em;
  width: 100%;
}

.csv-file-preview table {
  font-size: 0.7em;
}
.csv-file-preview table td {
  padding-right: 1.3em;
}

#docVideo {
  position: relative;
  height: 500px;
  border: solid 1px #eee;
  padding: 0;
  margin-bottom: 2em;
  box-sizing: content-box;
}

.external-judges-container {
}

.external-judge h2 {
  margin: 0;
  padding: 0;
}

.external-judge .person-info span,
.external-judge .contest-info span {
  padding-right: 1em;
}

.external-judge {
  margin: 1em;
  padding: 1em;
  border: 1px solid gray;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.external-judge .denied {
  font-weight: 800;
  position: absolute;
  left: 0;
  top: 30px;
  font-size: 0.8em;
  opacity: 0.5;
  transform: rotate(-45deg);
}
.card-header,
.card-body {
  position: relative;
}

.big-text-box {
  margin: 1em;
  padding: 1em;
  border: 1px solid gray;
  border-radius: 4px;
}

.hangup-button {
  transform: rotate(-135deg);
}

.video-gate {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(90, 90, 90, 0.9);
  color: #fff;
  font-size: 3em;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.unlocked .video-gate {
  display: none;
}

.topic-words {
  white-space: pre-wrap;
}

.time-display {
  /* position: fixed;
  top: 200px;
  right: 250px; */
  max-width: 300px;
  font-size: 3em;
  font-weight: 600;
  color: #bbb;
  background-color: rgba(40, 40, 40, 0.75);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.modal-not-bs {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-open {
  overflow: hidden;
}

.modal-content h3 {
  font-size: 18px;
  margin: 0 !important;
  padding: 0;
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center; */
  overflow-y: auto;
  max-height: 98vh;
  position: relative;
}

.modal-body {
  overflow-y: auto;
  border-top: 1px solid #aaaaaa;
  min-height: 150px;
  min-width: 150px;
  /* max-height: calc(100vh - 10em); */
}

.modal-full .modal-body {
  /* max-height: ; */
  max-height: calc(100vh - 8em);
  position: relative;
}

.modal-content.modal-m {
  width: min(500px, 100vw);
  max-width: min(500px, 100vw);
}

.modal-content.modal-l {
  width: min(760px, 100vw);
  max-width: min(760px, 100vw);
}

.modal-content.modal-xl {
  width: min(1080px, 100vw);
  max-width: min(1080px, 100vw);
}

.modal-content.modal-xxl {
  width: min(1200px, 100vw);
  max-width: min(1200px, 100vw);
}
.modal-content.modal-full {
  width: 100vw;
  max-width: 100vw;
  height: calc(100vh - 30px);
}

.grapes-js-container .gjs-editor-cont {
  /* height: 100% !important; */
  height: calc(100vh - 14em) !important;
  margin-top: -15px;
  margin-bottom: -15px;
}

.cke_contents {
  /* height: calc(100% - 300px) !important; */
  height: calc(100vh - 25rem) !important;
}

.grapes-js-button-bar {
  /* position: absolute;
  display: block;
  bottom: -2em;
  z-index: 10000; */
  margin-top: 2em;
  margin-left: -10px;
}

.round-border {
  border-radius: 6px;
}

.w-100 {
  width: 100%;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-between.middle,
.flex.middle {
  align-items: center;
}

.headroom-l,
.headroom {
  margin-top: 8px;
}
.headroom-xl {
  margin-top: 2em;
}
.bumper {
  padding: 0.5em 1em;
}
.bumper-l {
  padding: 8px;
}

.bumper-out-l {
  margin: 8px;
}

.soft-border {
  border-radius: 8px;
  border: 1px solid #777;
}

.click, .icon-btn{
  cursor: pointer;
}
.icon-btn {
  font-size: 1.2em;
}

.bumper-sides-l {
  padding-left: 8px;
  padding-right: 8px;
}

.bumper-sides-out {
  margin-left: 8px;
  margin-right: 8px;
}

.bumper-vert-out-l {
  margin-top: 8px;
  margin-bottom: 8px;
}

#z-index-control1 {
  z-index: 1;
}

#z-index-control2 {
  z-index: 1;
}

.wider-input-d {
  min-width: 150px;
}
.wider-input-t {
  min-width: 100px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
}

.bumper-sides-l {
  padding-left: 16px;
  padding-right: 16px;
}

.accordion {
  margin: 1em 0.5em;
  border-radius: 4px;
  border: 1px solid #aaa;
}

.accordion-header {
  font-size: 1.3em;
  font-weight: bolder;
  padding: 0.3em 0.7em;
  border-bottom: 1px solid #aaa;
}

.accordion-title {
}

.accordion-body {
  /* maybe a transition for smoother appearance? */

  padding: 0.3em 1.2em;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-assessment {
  padding: 4px;
}

.c-a-title {
  flex-grow: 1;
  width: 40%;
  flex-wrap: wrap;
  font-size: 1.15em;
  font-weight: 500;
}

.c-a-scoring-range {
  flex-grow: 1;
}
.c-a-weight {
  flex-grow: 1;
}

.dont-margin {
  margin: 0px !important;
}

.grow-1,
.grow {
  flex-grow: 1;
}

.rubric-header {
  display: flex;
  justify-content: space-between;
}

.wider {
  min-width: 50%;
}

.wrap {
  flex-wrap: wrap;
}

.judge-table {
  border-collapse: collapse;
  width: 100%;
}

.judge-table th,
.judge-table td {
  padding: 4px;
}

.judge-table tr:nth-child(even) {
  background-color: #eee;
}

.judge-table tr:hover {
  background-color: inherit;
  cursor: inherit;
  box-shadow: none;
}

.path-option {
  cursor: pointer;
}

.prompt-unit {
  padding: 8px;
  margin: 8px;
  width: 30%;
}

.limited-length {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.container .time-display {
  padding: 0;
  font-size: 1.2em;
  border-radius: 4px;
  padding: 0.25em 1em;
  margin-left: 1em;
  color: white;
}

.Toastify .acadeca-60 {
  background-color: blue;
  color: white;
}

.Toastify .acadeca-30 {
  background-color: rgb(240, 101, 171);
  color: white;
}

.Toastify .acadeca-0 {
  background-color: white;
  color: black;
}

.text-right {
  text-align: right;
}

.download-scores {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.download-scores div {
  padding: 1.4em;
  border-radius: 4px;
  border: 1px solid #aaaaaa;
  transition: ease-in-out 500ms;
  cursor: pointer;
}

.download-scores div:hover {
  transition: ease-in-out 1500ms;
  box-shadow: 2px 2px 5px #aaaaaa;
}

.loading-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.ready-to-load-area {
  border: 1px solid #2e7d32;
  background-color: #c8e6c9;
  border-radius: 8px;
  margin: 16px;
  padding: 16px;
}

.bumper-sides-out-l {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.boo img {
  position: absolute;
  z-index: 999999;
  top: 0;
}

.scare {
  position: fixed;
  bottom: 3px;
  right: 3px;
}
.paperwork-list-btn-bar {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.paperwork-list-btn-bar button {
  margin: 0.2em 0;
}

.spelling-bee-container {
  height: 400px;
  width: 100%;
}

i {
  margin-right: 0px !important;
}

.parent-section {
  height: 400px;
  padding: 8px;
  margin: 8px;
  border: 2px solid #444;
  border-radius: 8px;
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  box-sizing: border-box;
}

.video-section {
  height: 100%;
  flex-grow: 3;
  min-width: 500px;
  margin: 8px;
  /* min-width: 600px; */
  /* border: 2px solid aquamarine; */
}

.some-info-section {
  /* flex-grow: 1; */
  /* border: 2px solid pink; */
  margin: 8px;
  height: 100%;
}

.inside-info-section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.participants-section {
  /* height: 200px; */
  border: 2px solid #444;
  border-radius: 8px;
  margin: 8px;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}

.user-card {
  height: 85px;
  width: 70px;
  border: 5px solid #444;
  border-radius: 8px;
  color: #444;
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-card.ready {
  border: 5px solid #80b3ff;
  color: #80b3ff;
}

.user-card.notReady {
  border: 5px solid #444;
  color: #444;
}

.user-card.current {
  border: 5px solid #0dcc80;
  color: #0dcc80;
}

.user-card.eliminated {
  border: 5px solid #9a1a1a !important;
  color: #9a1a1a !important;
}

@media screen and (max-width: 700px) {
  .parent-section {
    flex-direction: column;
    overflow-y: auto;
  }

  .some-info-section {
    max-height: 50px;
  }

  .inside-info-section {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.video-project-id {
  font-size: 1.5em;
  font-weight: bolder;
}
.video-assignments {
  margin: 3em;
}
.video-assignments .assignment {
  margin: 0.3em 1em;
  border: 1px solid #aaaaaa;
  padding: 0.3em;
  border-radius: 4px;
}

.video-assignments .assignment.other-room {
  border-left: 10px solid #df8807;
}

.video-assignments .assignment button {
  margin-left: 2em;
}

.super-quiz-container {
  height: 100%;
  width: 100%;
  padding: 16px;
}

.judge-top-section {
  width: 100%;
  display: flex;
  padding: 16px;
  margin-bottom: 8px;
}

.video-box {
  height: 300px;
  min-width: 300px;
  flex-grow: 1;
  padding: 16px;
}

.judge-quiz-box {
  height: 300px;
  flex-grow: 2;
  padding: 16px;
  margin-left: 8px;
}

.bottom-section {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 16px;
}

.team-box {
  max-width: 300px;
  height: 100%;
  padding: 16px;
}

.team-member-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.student-left-section {
  height: 100%;
  min-width: 40%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  justify-content: space-evenly;
}

.student-quiz-box {
  border: 2px solid yellow;
  padding: 16px;
  margin: 16px;
}

.thick-soft-border {
}

.question-selection input {
  visibility: hidden;
}

.question-selection label {
  height: 30px;
  width: 100%;
  background-color: #fff;
  border: solid #ffe5bf;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 6px;
}

.question-selection label:hover {
  background-color: #ffe5bf;
  border: solid #ffcd80;
  cursor: pointer;
}

.question-selection input[type='radio']:checked + label {
  background-color: #ffcd80;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #444;
  font-weight: bold;
}

.round-thick-border {
  border: 2px solid #777;
  border-radius: 8px;
}

.question {
  font-size: larger;
  font-weight: 600;
}

.modal-topics:nth-child(odd) {
  background: #ddd;
}

.modal-topics {
  user-select: none;
}

.emph {
  font-weight: bold;
  font-size: 1.15em;
}

/* .btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out;
  transition: background-color .15s ease-in-out;
  transition: border-color .15s ease-in-out;
  transition: box-shadow .15s ease-in-out;
} */

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
  border: 1px solid #6c757d;
  border-radius: 0.25rem;
}

.btn-secondary:focus {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  /* border: 3px solid #6c757d; */
}

.btn {
  /* border: inherit; */
}

button {
  border-radius: 0.5rem;
}

.top-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #aaff99;
  color: #444;
  border: 1px solid #0dcc27;
  transition: height 0.5s;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 3;
}

.contained {
  max-height: 600px;
  overflow-y: auto;
}

.online-judging-dashboard {
  margin: 1em;
}

.online-judging-project-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.online-judging-project-list .project .id {
  text-align: center;
  font-weight: bold;
}

.online-judging-project-list .project .category {
  font-size: 0.8em;
  width: 160px;
  height: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.online-judging-project-list .project .language {
  font-size: 0.8em;
  width: 160px;
  /* height: 20px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.online-judging-project-list .project .category-no-space {
  font-size: 0.8em;
  width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.online-judging-project-list .project {
  padding: 0.4em;
  margin: 0.5em;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  width: 180px;
  height: 150px;
}

.online-judging-project-list .project .judges {
  font-size: 0.85em;
  max-height: 65px;
  background-color: burlywood;
  overflow: auto;
}

.online-judging-project-list .project:hover {
  box-shadow: 2px 2px 5px #aaaaaa;
}

.dashboard-tools .bell-button {
  font-size: 3em;
}

.dashboard-tools .tool {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  margin: 0.5em;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
}

.dashboard-tools .tool .btn {
  margin-right: 0;
}

.online-judging-dashboard .user-list {
  padding: 1em;
  font-size: 1.1em;
}
.online-judging-dashboard .user-list .user:nth-child(even) {
  background-color: #ccc;
}

.online-judging-dashboard .user-list .user {
  margin-bottom: 0.2em;
  padding: 0.1em 0.5em;
  border-radius: 0.2em;
}

.auto-plop-queue {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.auto-plop-queue > div {
  width: 40%;
  min-width: 300px;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  padding: 1em;
  margin: 2em;
}

.auto-plop-queue .inqueue {
  font-size: 1em;
  padding: 0.3em 1em;
}

.no-width-form-control {
  display: block;
  /* width: 100%; */
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.nice-tag {
  background-color: #eee;
  border-radius: 2px;
  padding: 2px 6px;
  /* max-width: 110px; */
  margin: 4px;
}

.loading-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 500px;
}

.w-30 {
  width: 30%;
}

.participant-video .title-bar,
.participant-video .navbar,
.participant-video .footer {
  display: none;
}

.participant-video .big-text-box {
  margin: 0.2em;
  padding: 0.3em 1em;
}

.participant-video .container {
  width: 98%;
  max-width: 1600px;
}

.participant-video #docVideo {
  height: calc(100vh - 200px);
  min-height: 500px;
}

.participant-video .head-room,
.participant-video .head-room-l,
.participant-video .head-room-xl {
  margin-top: 8px;
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}

.plop-table {
  width: 100%;
}

.plop-table th,
td {
  padding: 0 8px !important;
}

.first-time-modal-table tr:nth-child(even) {
  background-color: #e2e2e2;
}
.first-time-modal-table,
tr,
td,
th {
  padding: 4px;
}

.judge-category {
  font-size: smaller;
  margin-right: 1em;
  background-color: #ccc;
  padding: 0.2em;
  border-radius: 4px;
}

.locked {
  position: relative;
}
.unlocked .gate {
  display: none;
}

.locked .gate {
  position: absolute;
  text-align: center;
  padding: 2em;
  color: #fff;
  background-color: #333;
  background-color: rgba(0, 0, 0, 0.75);
  cursor: not-allowed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999999;
}

.circle-scheduler {
  margin: auto 1em;
}

.circle-scheduler .menu-bar {
  margin-bottom: 1em;
}

.circle-scheduler-step-tile {
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
}

.circle-scheduler-step-tile .step-tile {
  width: 275px;
  border: 1px solid #aaaaaa;
  border-radius: 0.3em;
  margin: 1em;
  padding: 1em;
}

.circle-scheduler-step-tile .step-tile button {
  margin-top: 0.5em;
}

.oj-circle-container {
}

.oj-circle .header {
  display: flex;
  justify-content: space-between;
}

.oj-circle .header .title {
  font-size: smaller;
  color: #555;
}
.oj-circle .assignments-container {
  display: flex;
  justify-content: space-evenly;
}
.oj-circle .assignments {
  padding: 0.5em;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.oj-circle .judge {
  padding: 0.2em 0.5em;
  border-radius: 3px;
  background-color: #ddd;
  margin: 0.25em;
}

.oj-circle .judge span {
  font-size: smaller;
  color: #777;
  padding-left: 2em;
}

.oj-circle-project {
  border: 1px solid #ccc;
  padding: 0.3em 1em;
  margin: 0.25em;
  border-radius: 3px;
}

.oj-circle-project:nth-child(even) {
  background-color: #f5f5f5;
}

.participant-control-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.participant-control-center .participant {
  background-color: #eee;
  border-radius: 4px;
  padding: 0.2em;
  margin: 0.15em;
}
.participant-control-center .user {
  text-align: center;
}

.participant-control-center .action {
  padding: 0.3em 0.3em;
  cursor: pointer;
  color: #aaa;
}

.participant-control-center .action:hover {
  color: #000;
}

/******************************************************

******************** Timer *****************************

********************************************************/

.time-display {
  /* position: fixed;
  top: 200px;
  right: 250px; */
  font-size: 3em;
  font-weight: 600;
  color: #bbb;
  background-color: rgba(40, 40, 40, 0.75);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.basic-single div {
  z-index: 1000;
  /* position: inherit; */
}

.awards-list {
  display: flex;
  flex-wrap: wrap;
}

.awards-list .award {
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  padding: 0.75em 1em;
  font-size: 1.3em;
  margin: 1em;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.awards-list .award:hover {
  box-shadow: 2px 2px 5px #aaa;
}

.awards-list .award .fad {
  font-size: 1.5em;
  padding-right: 1em;
}

.awards-list .award .award-sub {
  font-size: 0.8em;
  color: #333;
}

.award-certificate {
  background-color: #555;
  padding: 5px;
  overflow: auto;
}

.award-certificate .page {
  margin: 1em auto;
  padding: 3em;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 11in;
  height: 8.5in; */
  position: relative;
  width: 825px;
  /* height: 637.5px; */
  height: 618px;
  background-image: url('../public/border1.jpg');
  background-size: contain;
  background-repeat: no-repeat;
}

.award-certificate {
  font-size: 20px;
}

.award-certificate .fair-name {
  text-align: center;
  font-size: 1.75em;
  font-weight: 600;
}

.award-certificate .fair-name.has-logo {
  /* padding-left:190px;
  margin-left:0;
  margin-right:-8px;
  text-align: right; */
}

.award-certificate .fair-name-sub {
  text-align: center;
  font-size: 1.3em;
}

.award-certificate .award-text {
  text-align: center;
  font-size: 1em;
  font-variant: small-caps;
  margin: 0.5em;
}

.award-certificate .award-name {
  text-align: center;
  font-size: 2.4em;
  font-weight: bolder;
  text-decoration: underline;
  margin: 0.1em;
}

.award-certificate .award-title {
  text-align: center;
  font-size: 1.75em;
  font-weight: bolder;
}
.award-certificate .award-title-sub {
  text-align: center;
  font-size: 1.45em;
  font-variant: small-caps;
}

.award-certificate .award-logo {
  /* position: absolute;
  left:55px;
  top:65px; */
  max-width: 200px;
  max-height: 200px;
  z-index: 20;
}

.award-certificate .header {
  display: flex;
  align-items: center;
}

.advanced-judging-landing {
  padding: 0 1em;
}

.advanced-judging-landing .search-bar .category {
  padding: 0.53em 1em;
  border: 1px solid #aaa;
  border-radius: 6px;
  height: inherit;
}

.advanced-judging-landing .search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.advanced-judging-landing .search-bar label {
  margin-right: 2em;
}

.advanced-judging-landing .search-bar input {
  max-width: min(50vw, 400px);
}

.advanced-judging-landing .project-search-list {
  margin-top: 1em;
}

.advanced-judging-landing .project-search-list .project .button-panel {
  margin-right: 1em;
}

.advanced-judging-landing .project-search-list .project {
  padding: 0.53em 1em;
  border: 1px solid #aaa;
  border-radius: 6px;
  margin-bottom: 0.5em;
}

.advanced-judging-landing .assignment {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.53em 1em;
  border: 1px solid #aaa;
  border-radius: 6px;
  margin-bottom: 0.5em;
}

.advanced-judging-landing .assignment.assignment2 {
  border-color: #80b3ff;
}

.advanced-judging-landing .assignment .buttons {
  font-size: 1.2em;
  margin: auto 1em;
}

.advanced-judging-landing .assignment .times span {
  background-color: #ccc;
  padding: 0.25em 1em;
  margin: 0.2em;
  border-radius: 0.4em;
  display: inline-block;
  font-size: 0.8em;
}

.advanced-judging-landing .assignment .buttons span {
  margin-right: 1em;
}

.bumper-bottom {
  margin-bottom: 1em;
}
.bummper-left {
  padding-left: 1em;
}
.label.bummper-left {
  margin-left: 1em;
}
.advanced-judging-landing .project-search-list .project .main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.advanced-judging-landing .project-search-list .project:hover {
  box-shadow: 2px 2px 5px #aaa;
}

.advanced-judging-landing .schedule-slots {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.advanced-judging-landing .schedule-slots .panel {
  margin: 0.5em;
  width: 300px;
}
.advanced-judging-landing .schedule-slots .timeslot {
  padding: 0.25em;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.advanced-judging-landing .schedule-slots .timeslot.self {
  color: #375c94;
  font-size: 1.1em;
  font-weight: bolder;
}

.advanced-judging-landing .schedule-slots .timeslot:hover {
  box-shadow: 1px 1px 3px #aaa;
}

.advanced-judging-landing .schedule-slots .click {
  margin-right: 1em;
}

.slot-list {
  max-height: 300px;
  overflow-y: auto;
}

.slot-list .timeslot {
  font-size: 1.3em;
}

.slot-list .timeslot:hover {
  text-decoration: underline;
  cursor: pointer;
}
.slot-list .timeslot span {
  margin-right: 1em;
}

.support-chat-open-btn {
  font-size: 2.5em;
  position: fixed;
  z-index: 100;
  left: 0.25em;
  top: 0.25em;
  background-color: #aaa;
  border-radius: 1em;
  padding: 0.2em;
}

.support-chat-open-btn:hover {
  box-shadow: 2px 2px 5px #000;
}
.support-agent {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.support-agent .support-chat-open {
  position: relative;
  right: inherit;
  bottom: inherit;
  margin: 1em;
}

.support-chat-open {
  position: fixed;
  z-index: 1000;
  right: 0.25em;
  bottom: 0.25em;
  background-color: #aaa;
  border-radius: 0.5em;
  padding: 1em;
  width: min(350px, 100vw);
  height: min(500px, 100vh);
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 20px #000;
}

.support-chat-open .chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5em;
}

.support-chat-open .chat-body {
  flex-grow: 1;
  height: 100%;
  background-color: #bbb;
  overflow-y: auto;
}

.support-chat-open .chat-footer {
  display: flex;
  align-items: center;
}

.support-chat-open .chat-footer textarea {
  background-color: transparent;
  width: 100%;
  border: none;
  flex-grow: 1;
}

.message-tape .message-item {
  white-space: pre-wrap;
  background-color: #ddd;
  padding: 0.4em;
  border-radius: 0.4em;
  margin: 0.4em;
  margin-bottom: 1.4em;
  position: relative;
}

.message-tape .message-item.self {
  background-color: lightblue;
}

.message-tape .message-item.system {
  background-color: #caf2b8;
}

.message-tape .message-item .from-name {
  position: absolute;
  bottom: -1.5em;
}

.message-tape .from-name {
  font-size: 0.65em;
}

.support-chat-subscribe .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.support-chats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: auto;
  max-height: 100px;
}

.support-chats .support-chat {
  cursor: pointer;
  max-width: 200px;
  background-color: #ddd;
  padding: 0.1em 0.5em;
  border-radius: 0.2em;
  margin: 0.1em;
}

.support-chats .support-chat:hover {
  box-shadow: 3px 3px 10px #aaa;
}

.support-chats .support-chat.active {
  background-color: lightblue;
}

.category-tile-list {
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  padding: 1em;
}

.category-tile-list .category-tile {
  padding: 0.53em 1em;
  border: 1px solid #aaa;
  border-radius: 6px;
  margin-bottom: 0.5em;
  width: 270px;
  height: 100px;
  font-size: 1.4em;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.category-tile-list .category-tile:hover {
  box-shadow: 3px 3px 10px #aaaaaa;
  background-color: #aaaaaa;
}

.project-search-list .button-panel .click.assigned {
  color: green;
}

.student-name-list span {
  padding: 0.25em 0.4em;
  background-color: #ddd;
  border-radius: 0.2em;
  margin: 0.2em;
}

.like-container {
  /* position: absolute;
  bottom:0;
  right:0;
  z-index: 10; */
  font-size: 0.9em;
  cursor: pointer;
}
.showcase-project.details {
  width: 100%;
}
.showcase-project.details .like-container {
  font-size: 1.5em;
  position: sticky;
  bottom: -1em;
  background-color: #ffffff;
}

.project-info-panel {
  margin: 1em;
  padding: 1em;
}

.project-info-panel .question-container {
  padding: 0.7em;
  border-radius: 0.5em;
  border: solid 1px #ddd;
  margin: 1em auto;
}

.project-info-panel .heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.project-info-panel .question-container .question {
  margin: 0.8em;
}

.project-info-panel .question-container .question .project-question {
  color: #555;
}

.project-info-panel .question-container .question .project-answer {
  font-weight: normal;
  margin: 1em;
}

.call-in-info-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.call-in-info-line select {
  padding: 0.4em;
  border-radius: 1em;
  border-color: #ddd;
}

.nav li a {
  cursor: pointer;
}
.nav li button {
  border: none;
  background-color: transparent;
  color: #777;
}

.nav li button:hover {
  color: #333;
}

@media (max-width: 767px) {
  .navbar-nav {
    margin-left: 1rem !important;
    font-size: 1.2em;
  }

  .navbar-nav li {
    margin-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  .nav.navbar-right {
    margin-right: 0 !important;
  }
}

.navbar {
  min-height: 1em !important;
}

.space-div > div {
  margin: 0 1em;
}

.stats-info-panel .stats-header {
  /* max-width: 350px; */
  padding: 1em;
  border-radius: 0.2em;
  border: 1px solid #eee;
  margin: 0.5em;
  display: flex;
  justify-content: space-between;
}

.stats-info-panel .stats-body {
  padding: 1em;
  border-radius: 0.2em;
  border: 1px solid #eee;
  margin: 0.5em;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.stats-sub-stat td.col-1 {
  width: 250px;
}
.stats-sub-stat td.col-2 {
  width: 40px;
}
.stats-sub-stat td.col-3 {
  width: 40px;
}
.stats-sub-stat td.col-4 {
  width: 90px;
}

.big {
  font-size: 1.6em;
}
.small {
  font-size: 0.65em !important;
}

#quick-comments {
  padding: 20px;
}

.project-choice {
}

.hover-shadow {
  transition: all ease-in-out;
}

.hover-shadow:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: 1.05;
}

.project-summary {
  box-sizing: border-box;
  border: 2px solid #aaa;
  margin: 8px;
  padding: 8px;
  border-radius: 4px;
}

.head-room {
  margin-top: 1rem;
}

.tag{
  cursor: default;
}

.tag.tag-person {
  background-color: lightsalmon;
}

.tag.tag-project {
  background-color: lightblue;
}
.tag.tag-auto {
  background-color: #df8807;
  color:#fff;
}

.tag-bar .tag,
#tag-modal-my-tags .tag {
  font-size: 0.8em;
  padding: 0.2em 0.5em;
  border-radius: 0.25em;
  margin: 0.15em;
  display: inline-block;
}

optgroup.tags-project {
  background-color: lightblue;
}

optgroup.tags-person {
  background-color: lightsalmon;
}

.page-list {
  font-size: 1em;
  padding: 0 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.page {
  padding: 0.05em 0.4em;
  cursor: pointer;
  background-color: #aaa;
  border-radius: 0.4em;
  margin: 0.1em 0.1em;
}

.page.active {
  box-shadow: 2px 2px 5px #aaa;
  border: 1px solid #aaa;
  background-color: #fff;
}
.form-control.hide-ask-require {
  max-width: 110px;
}
.margin-0-for-form-group {
  font-size: 0.8em;
}

.margin-0-for-form-group .form-group {
  margin-bottom: 0px;
}

.make-the-modal-be-bigger {
  min-width: min(500px, 80vw);
  min-height: 45vh;
}

.offset-line-up {
  padding-left: 10px !important;
}

.pt-7 {
  padding-top: 7px;
}

.text-left {
  text-align: left !important;
}

.border {
  border: 1px solid #ccc;
}

.store-item {
  width: 200px;
  height: 300px;
  border-radius: 8px;
  padding: 1em;
  border: solid 1px #aaaaaa;
  margin: 1em;
  position: relative;
}
.store-item-image {
  max-width: 150px;
  max-height: 200px;
}

.profile-image .huge {
  font-size: 110px;
}
.profile-image img {
  max-width: 200px;
  max-height: 150px;
  border-radius: 4px;
  vertical-align: top;
}
.profile-image-container {
  display: flex;
}

.profile-image-container .btn i {
  font-size: 1.3em;
  margin: 0.5em;
}

.profile-image-container .profile-image i{
  font-size: 6rem;
  padding: 0;
}

.multi-select {
  /* margin:auto -15px; */
}

.form-horizontal.user-requirements .control-label {
  font-weight: normal;
  text-align: left;
}

.form-horizontal .control-label.col-md-12,
.form-horizontal .control-label.col-sm-12 {
  text-align: left;
}

.form-horizontal h4 {
  margin-top: 1.5em;
}

.user-requirements-container {
  padding: 1em 2em;
}

ul.none {
  list-style-type: none;
}

.done-page {
  padding: 1em;
}
.done-section {
  margin-bottom: 2em;
}

.image-editor {
  display: flex;
  flex-direction: column;
  /* height: 100%;
  width:100%; */
  position: absolute;
  top: 0;
  bottom: 20px;
  left: 0;
  right: 0;
}
.image-modal-sizer {
  width: min(800px, 98vw);
  height: 80vh;
  position: relative;
  overflow: hidden;
}

.image-modal-sizer .modal-body {
  height: 75vw;
  max-width: none;
}

.file-input-modal-sizer {
  width: min(600px, 98vw);
  /* height: 80vh; */
  position: relative;
  overflow: hidden;
}

.entry-button {
  width: 435px;
  padding: 1em;
  margin: 1em;
  border: 1px solid #aaa;
  border-radius: 0.5em;
  position: relative;
}

.entry-button.new {
  border-left: 10px solid #0dcc27;
}

.entry-button:hover {
  box-shadow: 2px 2px 5px #aaa;
}

.cart-coupon {
  color: #333;
  background-color: #aaa;
  border-radius: 0.5em;
  padding: 0.15em 1em;
  margin: 0.2em;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
}

.gallery .gallery-image {
  margin: 0.1em;
  border-radius: 0.41em;
  height: 100px;
  width: 115px;
  border: 1px solid #aaa;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.gallery .gallery-image:hover {
  box-shadow: 2px 2px 5px #aaa;
}

.gallery .gallery-image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.gallery .gallery-image .caption {
  position: absolute;
  bottom: 0.5em;
  left: 0.5em;
  right: 0.5em;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 0.25em;
  padding: 0.1em 0.3em;
}

i.larger {
  font-size: 1.5em;
}

.gallery-files {
  font-size: 1.3em;
  cursor: pointer;
}
.gallery-file:hover {
  /* box-shadow: 2px 2px 5px #aaa; */
  color: #000;
}

.file-input-preview-sizer {
  width: min(800px, 98vw);
  /* height: 80vh;  */
  position: relative;
  overflow: hidden;
}

.not-listed-special-sizer {
  width: min(600px, 98vw);
}

.file-input-preview-sizer .modal-body {
  max-height: 75vw;
  max-width: none;
}
.file-input-preview-sizer .file-preview {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 3em);
  position: relative;
}
.file-input-preview-sizer .file-preview video {
  margin-bottom: 1em;
}

.file-input-preview-sizer .file-preview .gallery-image {
  /* position: absolute;
  top:0;
  bottom:0; */
}

.supporting-document-list .file-preview embed {

  height: calc(100vh - 400px);

}

.file-preview .file-preview-grow {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}
.file-preview .gallery-image-x {
  object-fit: contain;
}
.file-preview .gallery-file-x {
  border: 1px solid #aaa;
  padding: 0.2em;
  margin-bottom: 0.5em;
  min-height: calc(90vh - 200px);
}

.gallery-prevew-caption {
  margin: 0.5em;
  margin-top: 0.1em;
  text-align: center;
}
.gallery-control-panel {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.has-error .show-on-has-error,
.has-error.show-on-has-error {
  display: inherit;
}
.show-on-has-error {
  display: none;
}

div.progress {
  height: 10px;
  margin-bottom: 3px;
}

.people-stats-summary {
  display: flex;
}

.people-stats-summary span {
  margin: 0.3em;
  padding: 0.2em 0.6em;
  background-color: #eee;
  border-radius: 0.3em;
}

.file-view-container {
  padding: 0 -2em -1em 1em;
  border: 2px solid #aaa;
}

.file-tite-bar {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}
.file-tile {
  margin: 0.25em;
  padding: 0.3em;
  width: 150px;
  border: 1px solid #aaa;
  border-radius: 0.2em;
  cursor: pointer;
}
.file-tile.required {
  border-left: 3px solid rgb(139, 5, 5);
}
.file-tile.active {
  background-color: #ddd;
}

.comments-container {
  max-height: calc(100vh - 300px);
  overflow: auto;
}

.review-panel .comments-container {
  max-height: calc(100vh - 500px);
}

.comments-container .comment {
  margin: 0.5em;
  background-color: #f4f4f4;
  border: 1px solid #eee;
  border-radius: 0.3em;
  padding: 0.5em 1em;
}
.comments-container .comment .comment-header {
  font-weight: 600;
}

.comments-container .comment .comment-by {
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.comments-container .comment .comment-message {
  white-space: pre-line;
}

.config-group-input-header {
  border-bottom: solid 1px #555;
  margin-top: 2em;
}

.config-group-input-header h4 {
  font-weight: bolder;
}

.config-group-input-inline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  /* border:1px solid #ddd;
  padding:.4em .4em; */
  margin: 0.3em;
  /* border-radius: 3px; */
  border-left: 4px solid #aaa;
}

.config-group-input-inline .config-form-landing-container {
  max-height: 100%;
}

.config-group-input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  padding: 0.4em 0.4em;
  margin: 0.3em;
  border-radius: 3px;
}

.config-group-input .config-switch {
  font-size: 2rem;
}

.config-group-input label {
  margin: 0;
}

.config-group-input select {
  margin-right: 1em;
}

.config-group-input:hover {
  box-shadow: 2px 2px 5px #ddd;
}
.config-group-input .click i {
  font-size: 2em;
}
.config-group-input .complex-btn i {
  font-size: 1em;
}

.config-group-input .btn-xs i {
  font-size: 1em;
}
.config-group-input .btn-xs {
  margin-left: 1em;
}

.plop-line {
  display: flex;
  flex-wrap: wrap;
}

.plop-line .plop-line-item {
  border: 1px solid #aaa;
  padding: 0.5em;
  margin: 0.4em;
  text-align: center;
  overflow: hidden;
  border-radius: 0.3em;
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.plop-line .plop-line-item.me {
  border-color: #333333;
  background-color: #555555;
  color: #ffffff;
}
.plop-line-number {
  font-size: 1.3em;
}
.plop-line-name {
  font-size: 0.7em;
}
.plop-line-time {
  display: flex;
  justify-content: space-between;
}

.requirement-flex {
  display: flex;
  align-items: baseline;
}

.requirement-flex .control-label {
  padding-top: 0 !important;
}

.isef-summary-approvals {
  margin: 0.25em 0;
}

.isef-summary-approvals span {
  font-size: 0.8em;
  background-color: #555;
  margin-right: 1em;
  padding: 0.3em 0.6em;
  border-radius: 3px;
  color: #fff;
}

.gen-form-tile-container {
  display: flex;
  flex-wrap: wrap;
}

.gen-form-tile-container .form-tile {
  padding: 0.8rem;
  padding-bottom: 2.5rem;
  width: max(345px, 30%);
  border: 1px solid #aaa;
  border-radius: 3px;
  margin: 0.4rem;
  cursor: pointer;
  position: relative;
  text-align: center;
}

.form-tile .form-tile-status{
  position: absolute;
  bottom: .5rem;
  right:.5rem;
}

.form-tile .form-tile-comments{
  position: absolute;
  bottom: .5rem;
  left:.5rem;
  color: #777;
}
.gen-form-tile-container .form-tile .banner{
  position: absolute;
  top: 0;
  left:0;
  right: 0;
  height: 1.8rem;
  padding: 0.2em 0.2em;
  border-radius: 3px 3px 0 0;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  /* background-color: #ddd;   */
}

.gen-form-tile-container .form-tile .banner .warning {
  
}

.gen-form-tile-container .form-tile.form-hide {
  color: #555;
  background-color: #ddd;
  border: 1px dashed #777;
  display: none;
}

.show-hidden-forms .gen-form-tile-container .form-tile.form-hide {
  display: block;
}

.review-panel {
  width: Min(450px, 30%);
  padding: 0.5em;
  border: 1px solid #aaaaaa;
  border-radius: 3px;
}

.packet-status {
  font-size: 1.5em;
}

.gen-form-tile-container .form-tile.sign-me {
  border-left: 4px blue solid;
}

.gen-form-tile-container .form-tile:hover {
  box-shadow: 2px 2px 5px #aaa;
}

.gen-form-tile-container .form-tile .title {
  margin-top: 1rem;
  font-size: 1.2em;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.packet-tile-container {
  display: flex;
  flex-wrap: wrap;
}

.packet-tile-container .packet-tile {
  margin: 1em;
  padding: 1em;
  width: min(450px, 30%);
  height: 400px;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  padding-bottom: 2rem;

  position: relative;
  display: flex;
  flex-flow: column;
}

.packet-tile-container .packet-tile .packet-bottom-banner{
  position: absolute;
  bottom: 0; right: 0; left: 0;
  border-radius: 0 0 4px 4px;
  background-color: #ddd;
  padding: .25rem 1rem;
  display: flex;
  justify-content: space-between;
}

.packet-tile-container .packet-tile .packet-body {
  flex-grow: 1;
  overflow: auto;
}

.packet-tile-container .packet-tile h4 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.packet-form-list {
  display: flex;

  flex-wrap: wrap;
}

.packet-form-list .packet-form-tile {
  margin: 1em;
  padding: 1em 0.5em;
  width: min(450px, 30%);
  height: 300px;
  border: 1px solid #aaaaaa;
  border-radius: 4px;

  display: flex;
  flex-flow: column;
}

.packet-form-list .packet-form-tile h4 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.packet-form-list .packet-form-tile .packt-form-tile-body {
  flex-grow: 1;
  overflow: auto;
}

.signer-form .editor-class {
  min-height: 100px;
}

.editor-class.editor-short {
  min-height: 150px;
  max-height: 200px;
  overflow: auto;
}

.form-manager-go-big {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.99);
  overflow: auto;
}

.form-manager-field-list .field-tile {
  border: 1px solid #aaaaaa;
  border-radius: 3px;
  padding: 0.6em 0.6em;
  margin-bottom: 0.5em;
}

.form-manager-go-big .field-maper {
}

.form-manager-go-big .field-maper .field-to-map {
}
.form-manager-go-big .field-maper .field-to-map.sel {
  background-color: #aaa;
}
.form-manager-go-big .field-maper .field-to-map.map {
  border-left: 10px solid brown;
}

.request-sig-options .sig-option {
  margin: 1em;
  padding: 0.5em 1em;
  border: 1px solid #aaa;
  border-radius: 3px;
  width: 133px;
  text-align: center;
  cursor: pointer;
}

.request-sig-options .sig-option.selected {
  background-color: #375c94;
  color: #ffffff;
}

.max-height-150 {
  height: 75px;
  overflow: auto;
}

.input-keys span {
  background-color: #ccc;
  padding: 0.3em 0.3em;
  border-radius: 3px;
  margin: 0.2em;
  font-size: 0.8em;
}

.wizard-group-container {
  display: flex;
  flex-wrap: wrap;
}

.wizard-group-container .wizard-tile {
  margin: 1em;
  padding: 1em;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  width: 350px;
  height: 250px;
  display: flex;
  flex-direction: column;
}

.wizard-flex-grow {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.wiz-config-list-container {
  overflow: auto;
  /* height: calc(100vh - 270px); */
  margin: 1em 0;
  border: 1px solid #aaaaaa;
  padding-bottom: 1em;
  position: relative;
}

.full-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.admin-sign-panel-signing-board {
  margin: 0 -2em -1em 1em;
  overflow: auto;
  padding-bottom: 100px;
  height: calc(100vh - 100px);
  max-width: min(calc(100vw - 325px), 1100px);
  background-color: #ababab;
  border: 1px solid #ababab;
}

.wiz-setup-type-tile {
  width: 300px;
  height: 250px;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin: 0.5em;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.wiz-setup-type-tile:hover {
  box-shadow: 3px 3px 8px #aaa;
}

.wizard-body {
}

.wizard-body .form-group {
  padding: .5rem;
}
.wizard-body .form-group:nth-child(even) {
  background-color: #f4f4f4;
}

.signers-list {
  border: 1px solid #aaa;
  overflow: auto;
  height: calc(100vh - 630px);
}

.signers-list .signer {
  border: 1px solid #aaa;
  padding: 0.5em;
  margin: 0.5em;
  border-radius: 3px;
}

.tags-list {
  border: 1px solid #aaa;
  overflow: auto;
  height: calc(100vh - 330px);
}

.tags-list .tag {
  margin: 0.3em;
  font-size: 1.2em;
  padding: 0.3em 1em;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
}

.entry-type-list {
  border: 1px solid #aaa;
  overflow: auto;
  height: max(300px, calc(100vh - 400px));
}

.entry-type-list .entry-type-tile {
  border: 1px solid #aaa;
  margin: 0.3em;
  font-size: 1.2em;
  padding: 0.3em 1em;
  border-radius: 3px;
}

.full-screen-preview {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1100;
  padding: 1em;
  background-color: #fff;
  overflow: auto;
}

.full-screen-preview .header {
  position: sticky;
  top: -1em;
  background-color: #ffffff;
  z-index: 10;
  border-bottom: 1px solid #aaaaaa;
}

.full-screen-preview .team-member {
  font-size: 0.9em;
  margin-right: 1em;
  color: #555555;
}

.full-screen-preview .header .close {
  font-weight: bold;
  font-size: 3em;
}

.problem-invoices-container .problem-invoice-group {
  border: 1px solid #aaaaaa;
  margin: 1em;
  padding: 1em;
  border-radius: 3px;
}

.al2-icon {
  width: 10%;
}
.al2-name {
  width: 25%;
}
.al2-username {
  width: 25%;
}
.al2-entry {
  width: 20%;
}
.al2-links {
  width: 20%;
}

.catagory-chair-container-list {
}

.catagory-chair-container-list .category-chair-container {
  border: 1px solid #aaaaaa;
  padding: 0.5em;
  border-radius: 4px;
  margin-bottom: 1em;
}

.catagory-chair-container-list .category-chair-container .category-chair-cap {
  margin-top: 0.3em;
}

.catagory-chair-container-list .category-chair-container .category-chair-cap:hover {
  background-color: #ddd;
}

.gated-entery-video {
  width: 400px;
  position: fixed;
  top: -1em;
  background-color: #fff;
  margin: 1em;
  padding: 1em;
  border-radius: 0.4em;
  box-shadow: 4px 4px 10px #444;
  right: 0;
}

.gated-entery-video .gated-entery-video-wait-list {
  max-height: min(80vh, 400px);
  overflow: auto;
}

.gated-entery-video .gated-entery-video-wait-list .gated-entry-waiter {
  border: 1px solid #aaaaaa;
  border-radius: 3px;
  margin-bottom: 0.4em;
  padding: 0.3em 0.5em;
}

.gated-entery-video .gated-entery-video-wait-list .gated-entry-waiter:hover {
  box-shadow: 2px 2px 5px #444;
}

.users-party-container {
  display: flex;
  flex-wrap: wrap;
}

.users-party-container .party-member {
  border: 1px solid #aaa;
  padding: 1em;
  width: 150px;
  height: 150px;
  margin: 0.5em;
  border-radius: 3px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.users-party-container .party-member:hover {
  box-shadow: 2px 2px 5px #aaaaaa;
}

.users-party-container .party-member .paid {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0dcc27;
  color: #ffffff;
}

.requested-interview-container-item {
  border: 1px solid #aaaaaa;
  border-radius: 3px;
  padding: 0.6em;
  margin-bottom: 1em;
}

.waitroom-request-container {
  display: flex;
  flex-wrap: wrap;
  max-height: 250px;
  overflow: auto;
}

.waitroom-request-container .waitroom-request {
  width: 160px;
  border: 1px solid #aaaaaa;
  padding: 0.5em;
  border-radius: 4px;
  margin: 0.5em;
  height: 160px;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.waitroom-request-container .waitroom-request .type {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #333333;
  color: #ffffff;
}
.waitroom-request-container .waitroom-request .language {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #777777;
  color: #ffffff;
}

.waitroom-request-container .waitroom-request .details {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.request-mod-or-interpreter {
  position: fixed;
  bottom: 1em;
  left: 1em;
  cursor: pointer;
  background-color: #555555;
  color: #ffffff;
  padding: 1em;
  border-radius: 99em;
  font-size: 1.1em;
  z-index: 50;
}

.request-mod-or-interpreter:hover {
  box-shadow: 3px 3px 10px #555555;
}

.event-session-list-container .event-session {
  border: 1px solid #aaaaaa;
  padding: 0.5em 1em;
  margin-bottom: 0.5em;
  border-radius: 3px;
}

.event-session-list-container .event-session .judging-session {
  background-color: #dddddd;
  padding: 0.3em 1em;
  margin-right: 1em;
  border-radius: 0.5em;
}

.order-history-panel .order-item-refunded {
  cursor: pointer;
  text-decoration: line-through;
}
.check-in-search {
  padding-bottom: 1em;
}
.check-in-user-container {
  max-height: calc(100vh - 375px);
  /* height: 500px; */
  width: 100%;
  overflow-y: auto;
  margin-top: 2em;
  border: 1px solid #aaaaaa;
}

.config-form-landing-container {
  overflow: auto;
  /* max-height: calc(100vh - 400px); */
  margin-bottom: 1rem;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  padding: 0.5em;
}

.config-form-landing-container .config-form-item {
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  padding: 0.5em;
  margin: 0.2em 0.5em 0.2em 0;
}

.config-form-landing-container .config-form-item:hover {
  box-shadow: 3px 3px 5px #aaaaaa;
}

.form-control.form-control-between {
  width: calc(50% - 2em);
}

.branch-dev-view {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: min(200px, 25%);
  overflow: hidden;
  background-color: #333333;
  z-index: 9999;
  color: #fff;
  padding: 1em;
}

.branch-dev-view.hide-dev {
  max-height: 0;
  padding: 0;
  overflow: initial;
}

.branch-dev-view .show-dev {
  position: fixed;
  left: 0;
  bottom: 0;
}

.branch-dev-view .steps {
  display: flex;
  flex-wrap: wrap;
}

.branch-dev-view .steps .trail-step {
  border: 1px solid #888888;
  border-radius: 3px;
  margin: 0.3em;
  padding: 0.4em;
}

.file-picker .thumbnail-1 {
  width: 75px;
  height: 75px;
  object-fit: cover;
}

.file-picker .thumbnail-1 img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.grade-range-input {
  display: flex;
  flex-wrap: wrap;
}

.grade-range-input .grade {
  background-color: #666;
  min-width: 50px;
  height: 50px;
  padding: 0.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #888;
  color: #fdfdfd;
  flex-wrap: wrap;
}

.grade-range-input .grade.active {
  background-color: #333;
  padding: 0.2em;
}

.grade-range-input .grade:hover {
  text-decoration: underline;
  background-color: #555;
}

.main-body {
  margin: 0 1em;
  display: flex;
}

@media screen and (max-width: 990px) {
  .main-body {
    flex-wrap: wrap;
    justify-content: center;
  }

  .main-body-side-bar {
    display: flex;
    justify-content: space-evenly;
    align-items: top;
    flex-wrap: wrap;
  }
}

.main-body-page {
  position: relative;
}
.main-body-page.edit {
  border: 1px solid #aaaaaa;
  box-shadow: 3px 3px 8px #aaaaaa;
  padding: 1rem;
  cursor: pointer;
}

.center-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  /* height: 100%; */
  max-width: calc(100vw - 2em);
  max-height: calc(100vh - 2em);
}

.image-block-md.blog-css .img-container {
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: min(33vw, 250px);
  max-height: min(50vh, 200px);
}

.image-block-md.blog-css .img-container img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 500px) {
  .image-block-md.blog-css .img-container {
    max-width: calc(100vw - 1em);
    max-height: calc(100vh - 1em);
  }
}

.branch-entry-bar {
  margin: 0.5em;
  margin-top: -0.5em;
  padding: 1em 2em 1em 2em;
  border: 1px solid #375c94;
  border-left: 10px solid #375c94;
}

.branch-entry-bar:hover .entry-bar-text {
  text-decoration: underline;
}

.branch-entry-bar .entry-bar-text {
  font-size: 1.5em;
  font-weight: bold;
}

.html-full-editor {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
}

.org-invoices,
.org-invoice {
  margin: 0 1rem;
}

.org-invoices .large,
.org-invoice .large {
  font-size: 1.5rem;
}

.org-invoice .btn {
  margin: 0;
}

.org-invoices .invoices-list {
}
.invoice-notes {
  margin-left: 1.5rem;
  font-size: 0.9rem;
}

.main.invoice-notes {
  margin-left: 1rem;
  font-size: 1rem;
  padding: 0.6rem 1rem;
  background-color: #eeeeee;
  border-radius: 1rem;
}
.invoice-item {
  font-size: 1.2rem;
  margin-left: 2rem;
  padding: 0.3rem 0.8rem;
  margin-top: 0.5rem;
}

.invoice-item:nth-child(even) {
  background-color: #eeeeee;
}

.org-invoices .invoices-list .invoice {
  border: 1px solid #aaaaaa;
  margin: 0.5rem 1rem;
  padding: 0.4rem;
  border-radius: 0.2rem;
}

.org-invoices .alert,
.org-invoice .alert {
  margin: 0;
}

.strong {
  font-weight: bolder;
  font-size: 1.1rem;
}

.soft {
  font-weight: lighter;
}

.setup-search-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: white;
}

.setup-search-container .background-white {
  background-color: white;
  padding: 1rem;
  margin-top: 0;
}

.system-tag-bubble-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  /* top:1rem; */
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  /* background-color: #0dcc27; */
}

.unpaid-org-invoices:hover,
.branch-search-help:hover {
  background-color: #fff;
  box-shadow: 2px 2px 5px #000;
}

.unpaid-org-invoices {
  border-radius: 50rem;
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid #333;
  font-size: 3.5rem;
  width: 5.3rem;
  margin: 0.5rem;
}

.branch-search-help {
  font-size: 3.5rem;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50rem;
  border: 2px solid #333;
  width: 5.3rem;
  z-index: 500;
  padding-right: 1rem;
}

.react-tabs {
}

.react-tabs .react-tabs__tab {
  background-color: #dddddd;
  border-radius: 0.5em 0.5em 0 0;
  bottom: 0;
}

.react-tabs .react-tabs__tab--selected {
  background-color: #ffffff;
  padding-top: 8px;
  bottom: -1px;
}

.multi-select .cool__menu {
  position: relative;
  margin: 0;
  padding-top: 0;
  border: 1px solid #dddddd;
}

.multi-select .cool__menu-list {
  margin-top: 0;
  padding-top: 0;
}

.multi-select .cool__option {
  font-size: 0.9em;
  padding: 0.3em 0.5em;
}

.special-award-component {
  border: 1px solid #aaaaaa;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
}

.special-award-component .header {
  background-color: #dddddd;
  padding-left: 1rem;
}

.special-award-component .assigned-entry {
  margin: 0.3rem 1rem;
}

.report-viewer {
  position: relative;
  overflow: auto;
  width: 8.5in;
  background-color: #dddddd;
  height: 11in;
  padding: 0.5in;
}

.report-viewer.landscape {
  width: 11in;
  height: 8.5in;
}

.judging-assignments-report-sickers-2pp {
  background-color: #ffffff;
  position: absolute;
  width: 10.5in;
  max-width: 10.5in;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.judging-assignments-report-sickers-2pp .jr-assignments {
  border: 1px solid #aaaaaa;
  width: 5in;
  padding: 1rem;
  height: 8in;
  overflow: hidden;
}

.people-page-search-bar {
  border-bottom: 1px solid #999999;
}

.people-page-search-bar .text-search,
.people-page-search-bar .create-search {
  width: 250px;
  margin: 0.2rem 1rem 0.2rem 0.5rem;
}

.people-page-search-bar .create-search {
  width: min(500px, calc(100vw - 4rem));
}

.person-row-select {
  margin-top: 1rem;
}

.multi-select .cool__menu-list {
  max-height: 150px;
  margin-top: 0;
  padding: 0;
}

.multi-select .cool__menu {
  margin: 0;
}

.multi-select .cool__option {
  padding: 0.3rem 0.1rem;
}

.filter-list {
  display: flex;
  flex-wrap: 1;
  align-items: center;
}

.filter-list .filter-item {
  background-color: #dddddd;
  border-radius: 0.2rem;
  padding: 0.2rem 0.4rem;
  margin: 0.2rem;
}

.branch-video-viewer iframe {
  width: 100%;
  height: min(60vh, 400px);
}

.tos {
  margin: 1rem;
  margin-top: 2rem;
  page-break-before: always;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #dddddd;
}

.assign-judge-wiz {
}

.judge-assign-cate-title-bar {
  padding-bottom: 1.5rem;
  background-color: #fff;
  border-bottom: 1px solid #888;
}

.judge-assign-cate-title-bar .stats {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% - 3rem);
  margin: 0 1.5rem;
}

.judge-assign-cate-title-bar .stats div {
  display: flex;
  justify-content: space-between;
  width: max(40%, 400px);
}

.assign-judge-wiz .judge-list .judge-info {
  border: 1px solid #aaaaaa;
  border-radius: 0.3rem;
  margin: 0.8rem;
  padding: 0.4rem 1rem;
}

.assign-judge-wiz .judge-list .judge-info:hover {
  box-shadow: 3px 3px 8px #aaaaaa;
}

.assign-judge-wiz .judge-prefs {
  display: flex;
  justify-content: center;
}

.assign-judge-wiz .judge-prefs span {
  background-color: #eeeeee;
  padding: 0.2rem 0.5rem;
  margin: 0.3rem 0.5rem;
  border-radius: 0.3rem;
  font-size: 0.8rem;
}

.results-round-list .round-list-item {
  border: 1px solid #aaa;
  border-radius: 0.4rem;
  margin: 0.5rem 0;
  padding: 0.7rem;
}

.results-round-list .round-list-item:hover {
  box-shadow: 3px 3px 8px #000;
}

.can-copy {
  cursor: pointer;
  margin: 0.25rem;
  padding: 0.15rem;
  background-color: #ddd;
  border-radius: 0.2rem;
}

.can-copy:hover {
  text-decoration: underline;
}

.can-copy-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 250px;
}

.orders-summary {
}

.orders-summary .record {
  border: 1px solid #aaa;
  margin-bottom: 0.5rem;
  padding: 0.3rem;
  border-radius: 0.3rem;
}

.orders-summary .record:nth-child(odd) {
  background-color: #eee;
}

.orders-summary .record .grow {
  margin: 0 0.6rem;
}

.orders-summary .coupon {
  background-color: #bbb;
  border-radius: 0.2rem;
  padding: 0.1rem 0.4rem;
  margin: 0.15rem 0.4rem;
}

.orders-summary .select-order {
  margin: 1rem 0rem;
}

.email-tokens {
  margin: 0.5rem 0rem;
}

.email-tokens span {
  padding: 0.3rem 0.5rem;
  margin: auto 0.4rem;
  border-radius: 0.3rem;
  background-color: #ddd;
}

.search-results {
}

.search-results .search-item:nth-child(even) {
  background-color: #fafafa;
}

.search-results .search-item {
  border: 1px solid #aaa;
  border-radius: 0.4rem;
  padding: 0.2rem 0.4rem;
  margin: 0.3rem 0;
}

.search-results .search-item:hover {
  box-shadow: 2px 2px 5px #333333;
}

.project-award-preview {
  margin: 0.4rem;
  padding: 0.5rem;
  border: 1px solid #aaaaaa;
  border-radius: 0.4rem;
}

.project-award-preview-list {
  max-height: 50vh;
  overflow: auto;
}

.is-test {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  color: white;
  padding: 0.3rem;
  font-size: large;
  text-align: center;
  background-color: #2e7d32;
}

.judge-group-list {
}

.judge-group-list .judge-group-list-item {
  border-radius: 0.3rem;
  border: 1px solid #aaaaaa;
  margin: 0.5rem 0rem;
  padding: 0.3rem 0.7rem;
}

.judge-group-list .judge-group-list-item .name {
  font-size: 1.3rem;
}

.judge-group-list .judge-group-list-item .judge {
  background-color: #cccccc;
  border-radius: 0.2rem;
  margin: 0.3rem;
  padding: 0.1rem 0.6rem;
}

.judging-cirlces-assignment-list {
}

.judging-cirlces-assignment-list .list-item,
.judging-cirlces-assignment-list .list-item tr > th {
  text-align: center;
}

.judging-cirlces-assignment-list tr td:first-child {
  text-align: left;
}

.judging-cirlces-assignment-list .list-item .title {
  font-size: 1.1rem;
  font-weight: 700;
}

.judging-cirlces-assignment-list .list-item:nth-child(even) {
  background-color: #cccccc;
}

.judging-circle-round-list .round-item {
  border: 1px solid #aaaaaa;
  border-radius: 0.3rem;
  padding: 0.3rem 0.7rem;
  margin: 1rem 0;
}

.judging-circle-round-list .round-item .header {
  border-bottom: 1px solid #aaaaaa;
}

.judging-circle-round-list .round-item:nth-child(even) {
  background-color: #dddddd;
}

.judging-circle-round-list .round-circle-list {
  display: flex;
  flex-wrap: wrap;
}
.judging-circle-round-list .round-circle-list .circle-item {
  width: 210px;
  margin: .5rem;
  padding: .2rem .5rem;
  border-radius: .3rem;
  border: 1px solid #bbbbbb;
  height: 95px;
}

.judging-circle-round-list .round-circle-list .circle-item .info{
  font-size: .8rem;
}

.judging-setup-rounds-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.judging-setup-rounds-list .judging-round-item{
  margin: .7rem;
  padding: .3rem .7rem;
  border:1px solid #aaaaaa;
  border-radius: .3rem;
  width: 265px;
  height: 115px;
}

.flex-flow-item-list{

}

.flex-flow-item-list .list-item{
  border:1px solid #aaaaaa;
  border-radius: .4rem;
  padding: .6rem;
  margin:.5rem 0;
}

.flex-flow-item-list .list-item .order{
  width: 45px;
  text-align: right;
  margin-right: .5rem;
  cursor: default;
}

.flex-flow-item-list .list-item .title{
  font-size: 1.1em;
  font-weight: bolder;  
}

.flex-flow-item-list .item-body{  
  margin:.5rem 0;
  margin-left: 3rem;
  border:1px solid #aaaaaa;
  border-radius: .4rem;
  padding: .6rem;
}

.pdf-basic-viewer{
  padding: .5rem;
  background-color: #aaaaaa;
}

.bold{
  font-weight: bold !important;
}

.people-col-1{
  position: relative;
}

#people-container .entry-status, .entry-button .entry-status{
  /* position: absolute; */
  /* writing-mode: vertical-rl;
  transform: rotate(180deg); */
  /* left: 2px; */
  text-align: center;

  /* bottom: 0; */
  /* margin: 1% auto; */
  background-color: #555555;
  color: #ffffff;
  padding: .2rem .45rem;
  cursor: default;
  border-radius: .3rem;
}


.entry-button .entry-status{
  position: absolute;
  top:0;
  right:0;  
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  font-size: .75rem;
}
#people-container .entry-status-approved, .entry-button.entry-status-approved,
#people-container .entry-status-dropped, .entry-button.entry-status-dropped,
#people-container .entry-status-dna, .entry-button.entry-status-dna{
 border-left: 8px solid gray;
}

.project-tile.entry-status-dna,.project-tile.entry-status-approved,.project-tile.entry-status-dropped{
  border: 1px solid blueviolet;
  border-left-width: 10px;
}

#people-container .entry-status-approved, .entry-button.entry-status-approved, .project-tile.entry-status-approved {
  border-color: green;
}
#people-container .entry-status-dropped, .entry-button.entry-status-dropped, .project-tile.entry-status-dropped {
  border-color: red;}
#people-container .entry-status-dna, .entry-button.entry-status-dna, .project-tile.entry-status-dna {
  border-color: yellow;}


.judge-assessment-submission-list{
  max-height: calc(100vh - 300px);
  overflow: auto;
}

.judge-assessment-submission-list .judge-submission{
  margin: 1rem 0;
  border: 1px solid #aaaaaa;
  border-radius: .3rem;
  padding: .3rem 1rem;
  position: relative;
}

.judge-assessment-submission-list .judge-submission .status{
  width: 100px;
  text-align: center;
}

.judging-feedback-preview .info {

}
.judging-feedback-preview .email {
  background-color: #dfdfdf;
  padding: 1rem;
  margin:.5rem;
  border-radius: .5rem;  
}

.judging-feedback-preview .email .subject
,.judging-feedback-preview .email .body
{
  background-color: #f3f3f3;
  border-radius: .3rem;
  padding: .3rem;
}

.judging-feedback-preview .email .body {
  height: 30vh;
  min-height: 250px;
  overflow: auto;
}

.flex-data-invoice{
  position: relative;
  margin: 1rem 0;
}

.flex-data-invoice .invoice{
  background-color: #fafafa;
  padding: 1rem;
  border-radius: .3rem;
}


@media (max-width: 900px) {
  .unpaid-org-invoices, .branch-search-help{
    position: relative;
    left: 0;
    right:0;
    top:0;
    bottom:0;
    border-radius: 1rem;    
    /* font-size: 2rem;     */
  }

  .system-tag-bubble-container{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
}

.pbplus-logo {
  height: 30px;
  margin: -5px;
}

.pbplus-stick-man-banner {
  border-color: #8409F9;
  position: relative;
  z-index: 0;
  max-width: 600px;
}

.pbplus-stick-man-banner.for-admin::after{
  font-size: smaller;
  content: 'promo';
  position: absolute;
  bottom: -1.4rem;
  right: .5rem;
  z-index: 10;
  background-color: #9a1a1a;
  color: #ffffff;
  padding: .2rem 1rem;
  padding-top: 0;
  border-radius: 0 0 .3rem .3rem;
}

.pbplus-stick-man-banner .btn.btn-info { 
  background-color: #8409f9;
  border: #8409f9;
  padding: .5rem 1.5rem;
}
.pbplus-stick-man-banner .btn.btn-info:hover {
  background-color: #8409f9;
  border: #8409f9;
}
.pbplus-terms-page {
  width: 80%;
  margin: auto;
}

.pbplus-terms-page .pbplus-leftside {
  flex-shrink: 0;
  margin: 2rem;
}
.pbplus-terms-page .pbplus-rightside {
    margin: 2rem;
}

.branch-entry-bar{
  box-shadow: 3px 3px 10px #aaaaaa;
}


.branch-entry-bar:hover{
  box-shadow: 3px 3px 8px #000000;
}


.image-gallery{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.image-gallery .image-of-gallery{
  margin: 1rem;
  flex-shrink: 0;
  flex-grow: 0;
  width: 250px;
  height: 250px;

  /* width: min(min(calc(100% - 1.5rem), calc(100vw - 3rem)), calc(100% / 3 - 2rem)); */
  /* height: min(min(calc(100% - 1.5rem), calc(100vw - 3rem)), calc(100% / 3 - 2rem)); */
  /* height: min(calc(420px - 1.5rem), min(calc(100vw - 3rem), calc(100vh / 4))); */
}

.image-gallery .image-of-gallery img{
  /* object-fit: cover; */
}

.tile-image {
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: #888;
}
.tile-image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}
.tile-image img.stock-image {
  object-fit: fill;
}
.tile-image img.img-back {
  object-fit: cover !important;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  filter: blur(1rem);
  /* filter:opacity(.6); */
}



.site-list {

}

.site-list .site {
  margin: 1rem;
  padding: 1rem;
  border: 1px solid #aaaaaa;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: 2px 2px 5px #aaa;
}

.site-list .site .site-logo {
  /* object-fit: fill; */
  width: 150px;
  height: 150px;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-list .site .site-logo i{
  font-size: 100px;
}

.site-select-btn {
  position: absolute;
  top:1rem;
  right:1rem;
}

.school-affiliate-list {

}

.school-affiliate-list .space {
  padding: 1rem;
  font-size: 1.3em;
}

.school-affiliate-list .list-item{
  border: 1px solid #aaaaaa;
  padding: .5rem;
  margin: .5rem;
  border-radius: .5rem;
}

.account-type-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.account-type-container .list-item {
  margin: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #aaaaaa;
  width: 320px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 8px #aaaaaa;
}

.account-type-container .list-item:hover {  
  box-shadow: 3px 3px 6px #aaaaaa;
}
.account-type-container .list-item .tile-label{
  font-size: 1.3rem;
}
.account-type-container .list-item .icon-image{
  font-size: 4rem;
}

.account-type-container .list-item .description {
  font-size: .85rem;
  flex-shrink: 1;
  overflow: auto;
}

.generic-form-viewer {
  position: absolute;
  top:0; 
  right:0;
  left:0;
  bottom:0;
  display: flex;  

}
.generic-form-viewer .side-bar{
  width: 350px;
}
.generic-form-viewer .main-content{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.generic-form-viewer .main-content .-header{
  flex-shrink: 0;
}
.generic-form-viewer .main-content .-footer{
  flex-shrink: 0;
}

.generic-form-viewer .main-content .main{
  flex-grow: 1;  
  overflow: auto;
  background-color: #aaaaaa;
  border: 1px solid #333;
  box-shadow: inset 5px 0px 5px #aaaaaa;
}

.generic-form-viewer .form-tiles {
  display: flex;
  flex-wrap: wrap;
}

.generic-form-viewer .form-tile{  
  margin: .5rem;
  padding: .5rem;
  display: flex;
  flex-direction: column;
  align-items: center;  
  /* justify-content: center; */
  cursor: pointer;
}
.generic-form-viewer .form-tile i{
  font-size: 3em;
}
.generic-form-viewer .form-tile.is-selected {
  border: 1px solid #375c94;
  box-shadow: 0px 0px 8px #375c94 inset;
;
  border-radius: .3rem;
}

.generic-form-viewer .form-status{
  padding:.5rem;
}

.generic-form-viewer .entry-info {
  padding: 1em;
}
.generic-form-viewer .entry-info .entry-id{
  font-size: 1.75em;
  font-weight: bold;
  text-align: center;
}
.generic-form-viewer .entry-info .title{
  font-size: 1.15em;
  font-weight: bold;
  text-align: center;
}
.generic-form-viewer .entry-info .category-info, .generic-form-viewer .entry-info .division{
  font-size: .9em;
}

.generic-form-viewer .owners-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.generic-form-viewer .owners-list .owner{
  margin: .5rem;
  padding: .5rem;
  border: 1px solid #aaaaaa;
  border-radius: .3rem;
  text-align: center;
}

.generic-form-viewer .owners-list .school{
  font-size: .65em;
}

.form-review-comment-list{
  max-height: 60vh;
  overflow: auto;  
}

.form-review-comment-list .comment-tile {
  position: relative;
  margin: 1.5rem .5rem;
  padding: .5rem;
  border-radius: .3rem;
  border: 1px solid #aaaaaa;    
}

.form-review-comment-list .comment-tile .comment-private{
  position: absolute;
  top:-.3rem;
  left:-.5rem;
  /* background-color: #ff0000; */
  color: #ff0000;
  padding: .2rem .5rem;
  border-radius: .3rem;
  font-size: x-small;
}

.form-review-comment-list .comment-tile .comment-at
{
  position: absolute;
  top:-1.2rem;
  right:0;
  font-size: small;
}

.form-review-comment-list .comment-tile .comment-status{
  position: absolute;
  top:-1.2rem;
  left:0;
  font-size: small;
}


.huge {
  font-size: 2.75em;
}

.x-editior{
  height: 25rem;
  /* height: max(calc(100vh - 75rem), 20rem); */
}

.wysiwyg .ql-editor{
  height: 15rem;
  max-height: calc(100vh - 20rem);
}
.wysiwg-expanded {
  z-index: 100;
}
.wysiwg-expanded .wysiwyg .ql-editor{
  height: calc(100vh - 25rem);
}

.quill{
  margin-bottom: 1rem;
}

.ql-align-center{
  text-align: center;
}
.ql-align-right{
  text-align: right;
}

.list-container{

}

.list-container .list-item .icon {
  font-size: 2rem;
  margin: .5rem;
  width: 50px;
}

.list-container .list-item{
  border: 1px solid #aaaaaa;
  border-radius: .3rem;
  padding: .5rem;
  margin: .5rem;
}

.list-container .list-item:hover{
  box-shadow: 3px 3px 8px #aaaaaa;
}


.slide-viewer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.slide-wrapper {
  transform: scale(.333);
  transform-origin: top left;
  /* height: calc(600px * .333);
  width: calc(800px * .333); */
  overflow: hidden;
  position: relative;
  margin-bottom: -400px;
  margin-right: -550px;
}

.slide-wrapper.active-slide {
  transform: none;
  position: fixed;
  background-color: #000;
  z-index: 50000000;
  bottom: 0;
  top: 0;
  left:0;
  right:0;
  margin: -1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.slide-wrapper .slide {
  /* transform: scale(.333); */
  width: 800px;
  height: 600px;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  font-size: 3em;
}
.slide-wrapper .slide p {
  font-size: .5em;
}

.slide-wrapper .slide h1 {
  font-size: 1.2em;
}

.slide-wrapper .slide h2{
  font-size: .9em;
  text-align: justify;
}

.slide .slide-sponsor-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.slide-sponsor-list .sponsor {
  width: 300px;
  height: 230px;
  overflow: hidden;
  object-fit: scale-down;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #fff; */
  border-radius: 1rem;
  margin: 1rem;
}