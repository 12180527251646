
.showcase-project.details{

}


.showcase-project.details .header{
  display: flex;
}

.showcase-project.details .box{
  border: 1px solid #ddd;
  margin:1em 0;
  padding:.3em;
}
.showcase-project.details .title-description{
  flex-grow: 1;
}
.showcase-project.details .primary-image{
  min-width: 40%;
  width: 40%;
  padding-right: .5em;
  height: 300px;
}
.showcase-project.details .primary-image img{
  object-fit: contain;
  height: 100%;
}
.showcase-project.details .project-owner-name{
  margin: .3em .5em;
  font-size: 1.1em;
  color:#444444;
}

.showcase-project.details .title{
  font-size: 1.5em;
  font-weight: bolder;
  margin-bottom: 1em;
}
.showcase-project.details .description{

}
.showcase-project.details .category-name{
  font-size: 1.8em;
  margin:.3em 0;  
  color:#777777;
}

.showcase-project.details .awards{
  font-size: 1.3em;
}

.showcase-project.details .media-grid{
  display: flex;
  flex-wrap: wrap;
}

.showcase-project.details .media-grid .media-el{
  width: calc(32% - .4em);
  height: 200px;
  object-fit: cover;
  margin: .4em;
}

@media screen and (max-width: 750px){
  .showcase-project.details .media-grid .media-el{
    width: calc(48% - .2em);
    height: 200px;
    object-fit: cover;
    margin: .2em;
  }
}

@media screen and (max-width: 500px){
  .showcase-project.details .media-grid .media-el{
    width: calc(98% - .2em);
    height: 200px;
    object-fit: cover;
    margin: .2em;
  }
}

.showcase-project.details .media-grid .media-el.file{
  border: solid 1px #aaaaaa;
  padding: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 6em;
  position: relative;
}

.showcase-project.details .media-grid .media-el img{
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.nice-tag {
    background-color: #eee;
    border-radius: 2px;
    padding: 2px 6px;
    /* max-width: 110px; */
    margin:4px;
  
  }