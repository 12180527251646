    .person-label {
        background-color: #DDD;
        border-radius: 4px;
        padding: .1rem .5rem ;
        margin: .5rem 0rem;
        white-space: nowrap;
        line-height: 2rem;
    }

    .person-label:nth-child(even){
        margin-left: .6rem;
    }

    .dropbox {
        outline: 2px dashed #999;
        /* the dash box */
        outline-offset: -10px;
        background: #ddd;
        color: #999;
        cursor: pointer;
    }

    .input-file {
        opacity: 0;
        /* invisible but it's there! */
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 200px;
    }

    .dropbox:hover {
        background: lightblue;
        /* when mouse over to the drop zone, change color */
    }

    .dropbox p {
        font-size: 1.2em;
        text-align: center;
        padding: 50px 0;
    }

    .project-tile {
        /* cursor: pointer; */
        padding: .4em;
        margin: .5rem 0;
        box-shadow: 0 0 5px #CCC;
    }

    .project-tile:hover {
        background-color: #FAFAFA;
        box-shadow: 0 0 5px #CCC;
    }

    .project-tile.review-ready.judging-ready {
        background: linear-gradient(.25turn, #66ff66, #ffffff, #ffffff);
    }

    .project-tile.review-ready {
        border-left: 4px solid #4c4;
    }

    .project-tile.judging-ready {
        border-right: 4px solid #4c4;
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 50%;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
        overflow-x: auto;
        max-height: 98vh;
    }

    .desk {
        background-color: #EEE;
        padding: 1em;
    }

    .pdf-viewer-container {
        position: absolute;
        bottom: 0;
        bottom: 0;
        top: 95px;
        left: 0;
        right: 0;
    }


    /* Empty Classes */

    .format-phone,
    .format-money,
    .format-date,
    .format-name,
    .use-file-picker-modal,
    .format-email,
    .focus,
    .EmailFilterTXT,
    .EmailFilterValue {}

    .project-images-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .password-error {
        padding: 1em;
        color: #a94442;
        background-color: #f2dede;
        border-color: #ebccd1;
        border-radius: 4px;
    }

    i {
        margin-right: .5rem;
    }

    .table>tbody>tr>td.head-room {
        padding-top: 1.75rem;
    }


    .desk {
        background-color: #EEE;
        padding: 1em;
    }

    .pdf-viewer-container {
        position: absolute;
        bottom: 0;
        bottom: 0;
        top: 95px;
        left: 0;
        right: 0;
    }

    @media print {
        .no-print {
            display: none !important;
        }

        div {
            page-break-inside: avoid;
        }

        html,
        body {
            height: 99%;
        }

        .panel {
            margin: 0;
            border: none;
        }
    }

    .no-bottom-border {
        border-bottom: none;
        border-left: none;
        border-right: none;
    }

    .search-max-column-title {
        max-width: 275px;
        word-wrap: break-word;
    }

    .search-max-column-school {
        max-width: 150px;
        word-wrap: break-word;
    }

    .search-max-column-category {
        max-width: 100px;
        word-wrap: break-word;
    }

    .search-max-column-name {
        max-width: 100px;
        word-wrap: break-word;
    }

    .text-button {
        cursor: pointer;
    }

    .text-button:hover {
        font-weight: bolder;
    }

    img {
        max-width: 100%;
    }

    .checkbox-real input[type=checkbox] {
        vertical-align: top;
        margin-right: 1em;
    }

    input[type=checkbox] {
        margin-right: 1em;
    }

    .profile-pic {
        max-width: 200px;
        max-height: 225px;
        border: solid 1px #DDD;
        border-radius: 4px;
        padding: 1em;
    }

    .icon-button {
        cursor: pointer;
        font-size: 1.1em;
    }

    .profile-pic-full {
        border: solid 1px #DDD;
        border-radius: 4px;
        padding: 1em;
        margin: 1em;
    }

    .project-pic {
        border: solid 1px #DDD;
        border-radius: 4px;
        padding: 1em;
        text-align: center;
        max-height: 175px;
        overflow: hidden;
    }

    .project-pic-sm img {
        max-height: 100px;
    }

    .project-pic button {
        position: absolute;
        right: 3px;
        bottom: 3px;
    }

    .clickable-fa {
        color: blue;
        cursor: pointer;
        font-size: larger;
    }

    .clickable-fa:hover {
        color: Navy;
    }

    .modal {
        overflow-y: scroll;
    }

    .label-inline label {
        display: inline;
    }

    .table-input {}

    .table.table-input>tbody>tr>td {
        padding: .24em;
    }

    .table-input label {
        margin: 0 .4em;
    }

    .table-input .form-control {
        padding: 0;
        height: 1.35em;
    }

    .container {
        padding-left: 0;
        padding-right: 0;
        background-color: #FFFFFF;
        /*box-shadow: 0 0 10px;*/
    }

    input.error,
    select.error,
    textarea.error {
        border-color: #a94442;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        -ms-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    }

    label.error {
        color: #a94442;
    }

    .help-video {
        cursor: pointer;
    }

    .help-video:hover {
        text-decoration: underline;
    }

    .padding {
        padding: .5em;
    }

    .padding-lg {
        padding: 1em;
    }

    .padding-xl {
        padding: 2em;
    }

    .padding-sm {
        padding: .25em;
    }

    .padding-xs {
        padding: .1em;
    }

    .margin {
        margin: .5em;
    }

    .margin-lg {
        margin: 1em;
    }

    .margin-xl {
        margin: 2em;
    }

    .margin-sm {
        margin: .25em;
    }

    .margin-xs {
        margin: .1em;
    }



    .selected {
        background-color: #addef7;
    }

    .slot-break {
        background-color: #b7b7b7;
    }

    .backdrop {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -10;
        background-image: url('./gplay.png');
        background-image: url('./gplay.png'), linear-gradient(150deg, #0492c7, #FFFFFF);
    }

    table {
        border: 0;
        padding: 0;
    }

    td {
        vertical-align: top;
        margin: 0;
        padding: 0;
    }

    hr {
        margin: 0 auto;
        border-color: #999999;
        border-color: rgba(0, 0, 0, .2);
        height: 1px;
    }

    iframe {
        border: none;
    }

    #AlertBoxContainer,
    #AlertBoxErrorContainer,
    #AlertBoxInfoContainer {
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 99999;
    }

    .shadow {
        background-color: rgba(50, 50, 50, .7);
    }

    .hide,
    .Hide,
    #hide,
    #Hide {
        display: none;
    }

    .DivButton {
        cursor: pointer;
        background-color: #DDD;
    }

    .DivButton-md {
        padding: 3px;
        margin-bottom: 2px;
    }

    .no-panel-margin {
        margin-bottom: 0;
    }

    .no-pad {
        padding: 0;
    }

    #xWindow {
        position: relative;
        max-width: 1100px;
        top: 0;
        bottom: 0;
        min-height: 800px;
        margin: 0 auto;
    }

    .max-window-width {
        max-width: 1100px;
    }

    .min-width-50 {
        min-width: 50px;
    }

    .max-300 {
        max-width: 300px;
    }

    .width-full {
        width: 1024px;
    }

    .arrow-btn {
        /* background-image: url('../images/arrowButtons.png'); */
        background-repeat: no-repeat;
        cursor: pointer;
        height: 48px;
        width: 48px;
    }

    .arrow-btn:hover {
        background-position-x: -48px;
    }

    .arrow-btn.top {
        background-position-y: 0;
    }

    .arrow-btn.bottom {
        background-position-y: -144px;
    }

    .arrow-btn.up {
        background-position-y: -48px;
    }

    .arrow-btn.down {
        background-position-y: -96px;
    }

    .titleBar {
        background-color: #0492c7;
        /*box-shadow: 0 0 .8em #888888; */
        /*font-variant: small-caps;*/
        left: 0;
        min-height: 90px;
        position: relative;
        /*text-shadow: 0.1em 0.1em 0.2em black;*/
        /* top: -14px; */
        margin-bottom: -14px;
        z-index: 200;
        /*-moz-border-radius: .5em;
    border-radius: .5em;*/
    }

    #NavBarDiv {
        /*display: inline-block;     vertical-align: top;     margin-right: 21px;     margin-bottom: 14px;*/
    }

    .searchRowHead {
        font-weight: 600;
    }

    #searchTable {}

    #body {
        margin-bottom: 2em;
        min-height: 45px;
    }

    #body .panel {
        margin-bottom: 0;
    }

    .panel,
    .panel-heading {
        -ms-border-radius: 0;
        border-radius: 0;
    }

    .bottomBar {
        background-color: #0492c7;
        bottom: 0;
        /*box-shadow: 0 0 .8em #888888;*/
        clear: both;
        height: 48px;
        left: 0;
        /*margin-top: 20px;*/
        overflow: hidden;
        position: relative;
        /*text-shadow: 0.1em 0.1em 0.2em black;*/
        z-index: 200;
        /*-moz-border-radius: 8px;
    border-radius: 8px;*/
    }

    .SetFocus {
        /* This element get fucus */
    }

    #version-number {
        position: absolute;
        right: 10px;
        bottom: 0;
        font-size: smaller;
        line-height: 48px;
        vertical-align: middle;
    }

    #copy-right {
        position: absolute;
        left: 3px;
        bottom: 0;
        font-size: smaller;
        color: #0492c7;
        height: 48px;
        width: 128px;
        cursor: pointer;
        overflow: hidden;
    }

    #copy-right>img {
        margin-top: 10px;
    }

    #copy-right a:link {
        color: #0492c7;
        text-decoration: none;
    }

    #copy-right a:hover {
        color: #0492c7;
        text-decoration: underline;
    }

    #copy-right a:visited {
        color: #0492c7;
        text-decoration: none;
    }

    #title {
        position: absolute;
        left: 2px;
        top: 15px;
        right: 0;
        max-width: 1300px;
        height: 60px;
        font-size: 28px;
        text-align: center;
    }

    #subtitle {
        position: absolute;
        left: 3px;
        top: 60px;
        right: 0;
        max-width: 1300px;
        font-size: 18px;
        text-align: center;
    }

    .popup-backdrop {
        background-color: rgba(50, 50, 50, .7);
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: block;
        z-index: 990;
    }

    .popup-close {
        text-align: right;
        position: absolute;
        top: -15px;
        right: 0;
        color: #CCC;
        padding: 2px;
        font-size: 12px;
        cursor: pointer;
    }

    .popup {
        background-color: #EEE;
        position: relative;
        display: block;
        z-index: 991;
        margin: 0 auto;
        margin-top: 20px;
        border: 2px outset #BBB;
    }

    .popup-content {
        position: absolute;
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
    }

    .loading {
        background-color: rgba(0, 0, 0, .7);
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        color: red;
        display: block;
    }

    /* .loading .img {
        background-image: url('../images/ANIM/whiteLoader.gif');
        width: 100px;
        height: 100px;
        margin: 0 auto;
    } */

    .more-info-icon {
        width: 20px;
        height: 20px;
    }

    #more-info-icon {
        width: 20px;
        height: 20px;
    }

    .text-area {
        width: 700px;
        margin: 0 auto;
    }

    .loading-cloud,
    #loading-cloud-screen {
        position: fixed;
        margin: 0 auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .7);
        z-index: 99999;
        color: #ffffff;
    }

    #userdiv {
        position: absolute;
        bottom: 6px;
        right: 10px;
        float: right;
        font-size: 10px;
        color: #fff;
        text-align: center;
        cursor: pointer;
    }

    .validation-error {
        color: Red;
    }

    .registration-steps {
        margin: 0 auto;
        clear: both;
    }

    .RegistrationStepsBtnDiv {
        width: 285px;
        float: left;
        height: 54px;
        margin: 5px;
        position: relative;
    }

    .RegistrationStepsBtnDiv span {
        position: absolute;
        left: 75px;
        top: 18px;
    }

    .RegistrationStepsBtnDiv img {
        width: 48px;
        height: 48px;
        left: 5px;
        top: 6px;
        position: absolute;
    }

    .RegistrationStepsLittleImage {
        width: 36px;
        height: 36px;
        margin: 0 auto;
    }

    .littleNav {
        height: 64px;
        margin-bottom: 1em;
    }

    .ResumeRegistrationLink {
        white-space: nowrap;
        overflow: auto;
        width: 700px;
        color: #0492c7;
    }

    .emailSpan {
        text-decoration: underline;
    }

    /*********************************************************************************************************/

    /*                                            USER CONTROLS                                              */

    /*********************************************************************************************************/

    /** GEN **/

    .centered {
        margin: 0 auto;
    }

    /*** Participant Search ***/

    .ParticipantSearchBox {
        border: 1px inset #0492c7;
    }

    .ParticipantSearchBox th {
        text-align: left;
        border-bottom: 1px solid #0492c7;
        vertical-align: bottom;
    }

    .tblSearchName {
        width: 270px;
    }

    .tblSearchProjectID {
        width: 130px;
    }

    .tblSearchTeam {
        width: 50px;
    }

    .tblSearchGrade {
        width: 50px;
    }

    .tblSearchBTNS {
        width: 100px;
    }

    .tblSearchAction {
        width: 100px;
    }

    .searchRow {
        cursor: pointer;
    }

    /* FairSetup */

    .menudivItem {
        width: 194px;
        height: 38px;
        background-color: #f0f0f0;
        border: 1px outset #0492c7;
        margin: 2px;
        font-size: 16px;
        cursor: pointer;
        padding: 2px;
    }

    .CateAwardMenuItem {
        width: 194px;
        height: 38px;
        background-color: #f0f0f0;
        border: 1px outset #0492c7;
        margin: 2px;
        font-size: 16px;
        cursor: pointer;
        padding: 2px;
    }

    .SpecialAwardMenuItem {
        height: 38px;
        background-color: #f0f0f0;
        border: 1px outset #0492c7;
        margin: 2px;
        font-size: 16px;
        cursor: pointer;
        padding: 2px;
    }

    .RequirementMenuItem {
        width: 194px;
        height: 28px;
        background-color: #f0f0f0;
        border: 1px outset #0492c7;
        margin: 2px;
        font-size: 16px;
        cursor: pointer;
        padding: 2px;
    }

    .DataTable {
        position: fixed;
        height: 200px;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;
        display: none;
    }

    /* Assign Category Awards */

    .QuickAssignBar {
        border: 1px solid #0492c7;
        height: 130px;
        width: 830px;
        margin: 0 auto;
        display: none;
        z-index: 999;
    }

    .QABItem {
        float: left;
    }

    .CA_JudingBar {
        font-size: 22px;
        border: 1px outset #0492c7;
        width: 830px;
        margin: 3px auto;
        position: relative;
    }

    .CA_JudingSelectCategory {
        font-size: 18px;
        border: 1px outset #0492c7;
        width: 830px;
        height: 200px;
        overflow: auto;
        margin: 3px auto;
        position: relative;
        display: none;
    }

    .CA_categoryMenuItem {
        height: 22px;
        background-color: #f0f0f0;
        border: 1px outset #0492c7;
        margin: 2px;
        font-size: 16px;
        cursor: pointer;
        padding: 2px;
    }

    .projectDescription {
        width: 520px;
        height: 36px;
    }

    .proRow {
        vertical-align: middle;
        padding: 0 2px;
        margin-bottom: 1px;
        background-color: #f0f0f0;
        border: 1px solid #0492c7;
        cursor: pointer;
    }

    /*.proRow div {float:left; border:0;}*/

    #assignAwardDiv {
        position: fixed;
        width: 520px;
        height: 230px;
        border: 4px outset #0492c7;
        padding: 3px;
        background: white;
        display: none;
        z-index: 99999;
    }

    /*Edit Custom Text */

    .EditCustomTextBTNBar button img {
        width: 1em;
        height: 1em;
    }

    .AdminPersonListing {
        position: relative;
    }

    .AdminPerson {
        border: 1px solid #0492c7;
        position: relative;
        height: 22px;
        width: 900px;
        margin: 0 auto;
        margin-bottom: 2px;
    }

    .AdminPersonName {
        position: absolute;
        width: 236px;
        overflow: hidden;
    }

    .AdminPersonEmail {
        position: absolute;
        top: 2px;
        left: 240px;
        width: 255px;
        overflow: hidden;
    }

    .AdminPersonPhone {
        position: absolute;
        top: 2px;
        left: 510px;
    }

    .AdminPersonUser {
        position: absolute;
        top: 2px;
        left: 655px;
    }

    .loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .SimpleFileViewer {
        padding: 2px;
        margin: 0 auto;
        background-color: #FFF;
        border: 2px outset #0492c7;
        width: 300px;
        overflow: hidden;
    }

    .SimpleFileViewer h4 {
        text-align: center;
    }

    .SimpleFileViewerSection {
        margin: 0 auto;
        width: 300px;
        overflow: auto;
        height: 208px;
    }

    .SimpleFileViewerSection div {
        width: 500px;
    }

    .SimpleFileViewerSection div img {
        width: 16px;
        height: 16px;
    }

    .SimpleFileViewerSection img {
        max-width: 64px;
        max-height: 64px;
        margin-right: 4px;
    }

    .FileUploader {
        cursor: pointer;
        background-color: #DDD;
        overflow: hidden;
    }

    .SimpleFileUploader {
        border: 1px solid #0492c7;
    }

    .RemoveFileBTN {
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    /* admin styles for edit custom text */

    #ADMIN_EDIT_CUSTOM_TEXT {
        margin: 0 auto;
        width: 750px;
    }

    .CustomTextCategory {
        float: left;
        margin: 3px;
        border: 2px outset #0492c7;
    }

    .CustomTextCategoryTitle {
        text-align: center;
        font-size: larger;
        font-weight: bolder;
    }

    .CustomText {
        margin: 1px;
        cursor: pointer;
        padding: 5px;
        width: 350px;
    }

    .CustomTextBodiesDiv {
        margin: 3px;
        position: relative;
        bottom: 3px;
    }

    .CustomTextBody {
        margin: 3px;
        border: 1px solid #0492c7;
        padding: 5px;
        position: relative;
        top: 2px;
        left: -1px;
        bottom: 3px;
    }

    .div_Title_Float {
        margin: 2px 0 0 -1px;
        padding: 2px 5px 0 5px;
        height: 24px;
        float: left;
        position: relative;
        width: auto;
        border: none;
        border: 1px solid #0492c7;
        cursor: pointer;
    }

    .div_Title_Float img {
        width: 20px;
        vertical-align: bottom;
        margin-right: 5px;
    }

    .category {
        position: relative;
        height: 90px;
        margin: 5px;
    }

    .categoryName {
        position: absolute;
        top: 3px;
        left: 3px;
        font-size: 18px;
    }

    .categoryDescription {
        position: absolute;
        top: 25px;
        left: 3px;
        height: 55px;
        overflow: auto;
    }

    .categoryShort {
        position: absolute;
        top: 3px;
        right: 3px;
        width: 10px;
        text-align: right;
        font-weight: lighter;
        display: none;
    }

    .editable {}

    .editable-show {
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding: 1em;
        margin: .5em;
        cursor: pointer;
    }

    /**************  Sponsor ********************/

    .sponsorMain {
        width: 700px;
        margin: 0 auto;
    }

    .sponsorItem {
        width: 128px;
        font-size: 14px;
        cursor: pointer;
        float: left;
        margin: 8px;
    }

    .sponsorItem img {
        max-width: 128px;
        max-height: 128px;
    }

    .sponsorDetail {
        width: 400px;
        margin: 0 auto;
        font-size: 24px;
        display: none;
    }

    /********************* Navigation **********************/

    #mainNavcDiv {
        /*font-variant: small-caps;*/
        float: left;
        /*overflow: visible;     position: relative;     margin: 0 auto;*/
        white-space: nowrap;
        background-color: #b7b7b7;
        padding: .24em;
        -ms-border-radius: .4em;
        border-radius: .4em;
        margin-bottom: 2em;
    }

    .NavLink {
        background-color: #b7b7b7;
        -ms-border-radius: .2em;
        border-radius: .2em;
        clear: both;
        color: #FFFFFF;
        cursor: pointer;
        font-size: 1.1em;
        line-height: 40px;
        /*margin-top: .3em;*/
        min-height: 40px;
        /*padding-left: 4px;*/
        position: relative;
        vertical-align: middle;
        width: 170px;
    }

    .nav-sub-menu {
        padding-right: 18px;
        padding-top: 4px;
        padding-bottom: 4px;
        position: absolute;
        left: 150px;
        top: -3px;
        width: 180px;
        z-index: 9999;
        display: none;
        border-radius: .5em;
        -moz-border-radius: .5em;
        background-color: #878787;
        border: 1px solid #555;
    }

    .nav-sub-menu .NavLink {
        background-color: #878787;
    }

    .NavLink img {
        max-height: 26px;
        max-width: 26px;
        position: relative;
        vertical-align: middle;
        margin-right: 2px;
    }

    .NavLink div {
        font-size: 15px;
        margin-left: 4px;
        text-align: Left;
        vertical-align: top;
    }

    .NavLink span {
        /*padding-left: 2px;*/
    }

    .nav-hover {
        background-color: #0492c7;
        box-shadow: inset 0 0 2em #333;
    }

    /*********************** Temp Person *************************/

    .tempPersonDiv {
        position: relative;
    }

    .tempPersonDiv img {
        width: 18px;
        height: 18px;
        cursor: pointer;
        vertical-align: bottom;
    }

    .tempPersonEmailDiv {
        position: absolute;
        right: .3em;
    }

    .tempPersonLinkDiv {
        font-size: 10px;
        clear: both;
        padding-left: 3px;
    }

    .tempPersonNameDiv {
        padding: 2px;
        float: left;
    }

    .content-border {
        margin: 0 auto;
        border: 1px solid;
    }

    .content-float-border {
        border: 1px solid;
        margin: 0 auto;
        padding: 16px;
    }

    .file-type-div,
    .judging-rubic {
        border: 1px solid;
        cursor: pointer;
        margin: 2px auto;
        position: relative;
        padding: 6px;
        overflow: hidden;
    }

    span.go-right {
        position: absolute;
        right: 6px;
    }

    .div-button {
        cursor: pointer;
        color: #888;
    }

    .div-button:hover {
        color: #000;
    }

    .file-type-div-container {
        margin: 0 auto;
        max-height: 600px;
        overflow: auto;
    }

    #FileTypeSetupPanel {
        background-color: #FFFFFF;
        margin: 0 auto;
        padding: 1.5em;
        position: relative;
        width: 500px;
        z-index: 200;
        border-radius: .5em;
        -moz-border-radius: .5em;
    }

    .studentsPaperworkFileComments {
        /* background-image: url('../images/bubblechat32.png'); */
        width: 32px;
        height: 32px;
    }

    .selected-file {
        color: #0492c7;
        border: 1px solid #0492c7;
    }

    .has-file {
        cursor: pointer;
        background-color: #bbbbbb;
    }

    .studentsPaperworkNewFile.Plus {
        background-position: 160px;
    }

    .new-paperwork {
        font-weight: bolder;
        font-size: 18px;
        color: orange;
    }

    .check-paperwork {
        font-weight: bolder;
        font-size: 16px;
        color: darkolivegreen;
    }

    .done-paperwork {
        font-weight: bolder;
        font-size: 16px;
        color: green;
    }

    .minus-paperwork {
        font-weight: bolder;
        font-size: 14px;
        color: darkred;
    }

    .warning-paperwork {
        font-weight: bolder;
        font-size: 14px;
        color: darkgoldenrod;
    }

    .unknown-paperwork {
        font-weight: bold;
        font-size: large;
        color: inherit;
    }

    .studentsPaperworkFile {
        cursor: pointer;
        /* background-image: url('../images/_Documents32.png'); */
        /*float: left;*/
        top: -7px;
        width: 32px;
        height: 32px;
        position: relative;
        -webkit-box-shadow: 0 0 10px;
        -ms-box-shadow: 0 0 10px;
        box-shadow: 0 0 10px;
        -ms-border-bottom-right-radius: .3em;
        border-bottom-right-radius: .3em;
        -ms-border-bottom-left-radius: .3em;
        border-bottom-left-radius: .3em;
        display: none;
    }

    .studentsPaperworkFile.Minus {
        background-position: 32px;
    }

    .studentsPaperworkFile.Warning {
        background-position: 64px;
    }

    .studentsPaperworkFile.Question {
        background-position: 96px;
    }

    .studentsPaperworkFile.Check {
        background-position: 128px;
    }

    .studentsPaperworkFile.Plus {
        background-position: 160px;
    }

    .studentsPaperworkFile.Star {
        background-position: 192px;
    }

    .studentsPaperworkFile.Blank {
        background-position: 224px;
    }

    .file-status-100,
    .file-status-300,
    .file-status-400 {
        background-color: #ff6;
    }

    .file-status-200 {
        background-color: #f66;
    }

    .file-status-500 {
        background-color: #6f6;
    }

    #editParticipantDiv {
        position: fixed;
        top: 150px;
        width: 850px;
        background-color: White;
        margin: 0 auto;
        z-index: 9999;
    }

    .form-horizontal {
        padding-left: 1.1em;
        padding-right: 1.1em;
    }

    .form-horizontal label {
        text-align: right;
    }

    .form-group {
        margin: .2em;
    }

    .judging-rubic-assessment {
        width: 750px;
    }


    .popup-edit-panel {
        position: fixed;
        top: 200px;
        width: 900px;
        z-index: 1001;
    }

    #javascriptWarningMessage {
        left: 2em;
        position: fixed;
        right: 2em;
        bottom: 0;
        z-index: 9001;
    }

    #body .panel-primary {
        margin-bottom: 0;
    }

    .modal-content .panel {
        margin-bottom: 0;
    }

    .panel .panel-body {
        padding-top: 1em;
        padding-bottom: 1em;
    }

    #body .panel-default {
        margin-bottom: 20px;
    }

    .float-right {
        float: right;
    }

    .table>tbody>tr>td,
    .table>tbody>tr>th,
    .table>tfoot>tr>td,
    .table>tfoot>tr>th,
    .table>thead>tr>td,
    .table>thead>tr>th .panel {
        padding: 0;
    }

    .panel.no-shadow {
        -webkit-box-shadow: none;
        -ms-box-shadow: none;
        box-shadow: none;
    }

    .panel-hover {
        cursor: pointer;
    }

    .panel-hover:hover {
        -webkit-box-shadow: 0 0 .8em #555;
        -ms-box-shadow: 0 0 .8em #555;
        box-shadow: 0 0 .8em #555;
    }

    .logo-max-size {
        max-width: 200px;
        max-height: 100px;
    }

    .logo-title-bar {
        max-width: 200px;
        max-height: 80px;
        margin: 4px;
        margin-left: 8px;
    }

    .pad-top {
        padding-top: 1em;
    }

    .panel-primary>.panel-heading {
        background-color: #0492c7;
    }

    .panel-primary {
        border-color: #0492c7;
    }

    .tight .col-xs-1,
    .tight .col-xs-2,
    .tight .col-xs-3,
    .tight .col-xs-4,
    .tight .col-xs-5,
    .tight .col-xs-6,
    .tight .col-xs-7,
    .tight .col-xs-8,
    .tight .col-xs-9,
    .tight .col-xs-10,
    .tight .col-xs-11,
    .tight .col-xs-12,
    .tight .col-sm-1,
    .tight .col-sm-2,
    .tight .col-sm-3,
    .tight .col-sm-4,
    .tight .col-sm-5,
    .tight .col-sm-6,
    .tight .col-sm-7,
    .tight .col-sm-8,
    .tight .col-sm-9,
    .tight .col-sm-10,
    .tight .col-sm-11,
    .tight .col-sm-12,
    .tight .col-md-1,
    .tight .col-md-2,
    .tight .col-md-3,
    .tight .col-md-4,
    .tight .col-md-5,
    .tight .col-md-6,
    .tight .col-md-7,
    .tight .col-md-8,
    .tight .col-md-9,
    .tight .col-md-10,
    .tight .col-md-11,
    .tight .col-md-12,
    .tight .col-lg-1,
    .tight .col-lg-2,
    .tight .col-lg-3,
    .tight .col-lg-4,
    .tight .col-lg-5,
    .tight .col-lg-6,
    .tight .col-lg-7,
    .tight .col-lg-8,
    .tight .col-lg-9,
    .tight .col-lg-10,
    .tight .col-lg-11,
    .tight .col-lg-12 {
        padding-right: 3px;
        padding-left: 3px;
    }

    .heading {
        font-weight: bold;
    }

    .sponsorItem2 {
        padding: 3px;
        overflow: hidden;
        font-size: 14px;
        cursor: pointer;
        margin: 3px;
        border: 1px solid gray;
        margin: 1px;
        background-color: #DDDDDD;
    }

    .file-selector-panel .thumbnail-max-64 {
        max-height: 64px;
        max-width: 64px;
    }

    .image-thumbnail {
        display: inline-block;
        background-color: #fff;
        border: 1px solid transparent;
        -ms-border-radius: 4px;
        border-radius: 4px;
        -webkit-box-shadow: 0 0 .2em #888888;
        -ms-box-shadow: 0 0 .2em #888888;
        box-shadow: 0 0 .2em #888888;
        margin: .2em;
        padding: .4em;
    }

    .block {
        display: block;
    }

    .file-selector-panel {
        max-height: 235px;
        overflow-y: auto;
    }

    .quick-assign-proj-panel {
        margin: .8em;
        padding: .5em;
    }

    .quick-assign-proj-panel.sm {
        font-size: 80%;
        margin-left: 16px;
        margin-right: 16px;
        -ms-opacity: .6;
        opacity: .6;
    }

    .quick-assign-proj-panel.xs {
        font-size: 65%;
        margin-left: 24px;
        margin-right: 24px;
        -ms-opacity: .3;
        opacity: .3;
    }

    input.form-control:hover,
    select.form-control:hover {
        -webkit-box-shadow: 0 0 1px #000;
        -ms-box-shadow: 0 0 1px #000;
        box-shadow: 0 0 1px #000;
    }

    .text-smaller {
        font-size: smaller;
    }



    .file-type-div .name {
        margin-left: .5em;
    }

    .row .navbar-collapse,
    .navbar .container-fluid {
        margin-left: -20px;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-default .navbar-nav>li>a {
        padding: 0 4px;
    }

    .navbar {
        min-height: 30px;
    }

    .navbar-default {
        margin-bottom: 0;
    }

    #collapseNavID,
    .navbar-default {
        background-color: transparent;
    }

    .small-device .logo-title-bar {
        max-width: 100px;
        max-height: 50px;
        margin: 3px;
        margin-left: 5px;
    }

    .small-device .pad-top {
        padding-top: .33em;
    }

    .manager-item-container {
        max-height: 400px;
        min-height: 100px;
        overflow: auto;
        border: gainsboro 1px solid;
        -ms-border-radius: 4px;
        border-radius: 4px;
    }

    .soft-border {
        border: gainsboro 1px solid;
        -ms-border-radius: 4px;
        border-radius: 4px;
        padding: .5em;
    }

    .manager-item-container .alert {
        margin: 2px;
    }

    @media (max-width: 767px) {
        .nav-sub-menu {
            left: .667em;
        }

        .container {
            padding: 0;
        }
    }

    .row-hover {
        margin-bottom: 2px;
        padding: 1px;
        cursor: default;
    }

    .row-hover:hover {
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .4);
        -ms-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .4);
        box-shadow: inset 0 0 10px rgba(0, 0, 0, .4);
        -ms-border-radius: 2px;
        border-radius: 2px;
    }

    .badge-light {
        font-weight: lighter;
        background-color: #777 !important;
    }

    .height-250 {
        max-height: 1150px;
        overflow: auto;
    }

    .height-200 {
        max-height: 1200px;
        overflow: auto;
    }

    .height-150 {
        max-height: 1250px;
        overflow: auto;
    }

    .height-100 {
        max-height: 1300px;
        overflow: auto;
    }

    .height-75 {
        max-height: 1325px;
        overflow: auto;
    }

    .height-50 {
        max-height: 1350px;
        overflow: auto;
    }

    @media (max-height: 1400px) {
        .height-150 {
            max-height: 1250px;
            overflow: auto;
        }
    }

    @media (max-height: 1375px) {
        .height-150 {
            max-height: 1225px;
            overflow: auto;
        }
    }

    @media (max-height: 1350px) {
        .height-150 {
            max-height: 1200px;
            overflow: auto;
        }
    }

    @media (max-height: 1325px) {
        .height-150 {
            max-height: 1175px;
            overflow: auto;
        }
    }

    @media (max-height: 1300px) {
        .height-150 {
            max-height: 1150px;
            overflow: auto;
        }
    }

    @media (max-height: 1275px) {
        .height-150 {
            max-height: 1125px;
            overflow: auto;
        }
    }

    @media (max-height: 1250px) {
        .height-150 {
            max-height: 1100px;
            overflow: auto;
        }
    }

    @media (max-height: 1225px) {
        .height-150 {
            max-height: 1075px;
            overflow: auto;
        }
    }

    @media (max-height: 1200px) {
        .height-150 {
            max-height: 1050px;
            overflow: auto;
        }
    }

    @media (max-height: 1175px) {
        .height-150 {
            max-height: 1025px;
            overflow: auto;
        }
    }

    @media (max-height: 1150px) {
        .height-150 {
            max-height: 1000px;
            overflow: auto;
        }
    }

    @media (max-height: 1125px) {
        .height-150 {
            max-height: 975px;
            overflow: auto;
        }
    }

    @media (max-height: 1100px) {
        .height-150 {
            max-height: 950px;
            overflow: auto;
        }
    }

    @media (max-height: 1075px) {
        .height-150 {
            max-height: 925px;
            overflow: auto;
        }
    }

    @media (max-height: 1050px) {
        .height-150 {
            max-height: 900px;
            overflow: auto;
        }
    }

    @media (max-height: 1025px) {
        .height-150 {
            max-height: 875px;
            overflow: auto;
        }
    }

    @media (max-height: 1000px) {
        .height-150 {
            max-height: 850px;
            overflow: auto;
        }
    }

    @media (max-height: 975px) {
        .height-150 {
            max-height: 825px;
            overflow: auto;
        }
    }

    @media (max-height: 950px) {
        .height-150 {
            max-height: 800px;
            overflow: auto;
        }
    }

    @media (max-height: 925px) {
        .height-150 {
            max-height: 775px;
            overflow: auto;
        }
    }

    @media (max-height: 900px) {
        .height-150 {
            max-height: 750px;
            overflow: auto;
        }
    }

    @media (max-height: 875px) {
        .height-150 {
            max-height: 725px;
            overflow: auto;
        }
    }

    @media (max-height: 850px) {
        .height-150 {
            max-height: 700px;
            overflow: auto;
        }
    }

    @media (max-height: 825px) {
        .height-150 {
            max-height: 675px;
            overflow: auto;
        }
    }

    @media (max-height: 800px) {
        .height-150 {
            max-height: 650px;
            overflow: auto;
        }
    }

    @media (max-height: 775px) {
        .height-150 {
            max-height: 625px;
            overflow: auto;
        }
    }

    @media (max-height: 750px) {
        .height-150 {
            max-height: 600px;
            overflow: auto;
        }
    }

    @media (max-height: 725px) {
        .height-150 {
            max-height: 575px;
            overflow: auto;
        }
    }

    @media (max-height: 700px) {
        .height-150 {
            max-height: 550px;
            overflow: auto;
        }
    }

    @media (max-height: 675px) {
        .height-150 {
            max-height: 525px;
            overflow: auto;
        }
    }

    @media (max-height: 650px) {
        .height-150 {
            max-height: 500px;
            overflow: auto;
        }
    }

    @media (max-height: 625px) {
        .height-150 {
            max-height: 475px;
            overflow: auto;
        }
    }

    @media (max-height: 600px) {
        .height-150 {
            max-height: 450px;
            overflow: auto;
        }
    }

    @media (max-height: 575px) {
        .height-150 {
            max-height: 425px;
            overflow: auto;
        }
    }

    @media (max-height: 550px) {
        .height-150 {
            max-height: 400px;
            overflow: auto;
        }
    }

    @media (max-height: 525px) {
        .height-150 {
            max-height: 375px;
            overflow: auto;
        }
    }

    @media (max-height: 500px) {
        .height-150 {
            max-height: 350px;
            overflow: auto;
        }
    }

    @media (max-height: 475px) {
        .height-150 {
            max-height: 325px;
            overflow: auto;
        }
    }

    @media (max-height: 450px) {
        .height-150 {
            max-height: 300px;
            overflow: auto;
        }
    }

    @media (max-height: 425px) {
        .height-150 {
            max-height: 275px;
            overflow: auto;
        }
    }

    @media (max-height: 400px) {
        .height-150 {
            max-height: 250px;
            overflow: auto;
        }
    }

    @media (max-height: 375px) {
        .height-150 {
            max-height: 225px;
            overflow: auto;
        }
    }

    @media (max-height: 350px) {
        .height-150 {
            max-height: 200px;
            overflow: auto;
        }
    }

    @media (max-height: 325px) {
        .height-150 {
            max-height: 175px;
            overflow: auto;
        }
    }

    @media (max-height: 300px) {
        .height-150 {
            max-height: 150px;
            overflow: auto;
        }
    }

    @media (max-height: 275px) {
        .height-150 {
            max-height: 125px;
            overflow: auto;
        }
    }

    @media (max-height: 250px) {
        .height-150 {
            max-height: 100px;
            overflow: auto;
        }
    }

    @media (max-height: 225px) {
        .height-150 {
            max-height: 75px;
            overflow: auto;
        }
    }

    @media (max-height: 200px) {
        .height-150 {
            max-height: 50px;
            overflow: auto;
        }
    }

    @media (max-height: 175px) {
        .height-150 {
            max-height: 25px;
            overflow: auto;
        }
    }

    @media (max-height: 150px) {
        .height-150 {
            max-height: 0px;
            overflow: auto;
        }
    }

    @media (max-height: 125px) {
        .height-150 {
            max-height: -25px;
            overflow: auto;
        }
    }

    @media (max-height: 100px) {
        .height-150 {
            max-height: -50px;
            overflow: auto;
        }
    }

    @media (max-height: 75px) {
        .height-150 {
            max-height: -75px;
            overflow: auto;
        }
    }

    @media (max-height: 50px) {
        .height-150 {
            max-height: -100px;
            overflow: auto;
        }
    }

    @media (max-height: 25px) {
        .height-150 {
            max-height: -125px;
            overflow: auto;
        }
    }

    @media (max-height: 1400px) {
        .height-100 {
            max-height: 1300px;
            overflow: auto;
        }
    }

    @media (max-height: 1375px) {
        .height-100 {
            max-height: 1275px;
            overflow: auto;
        }
    }

    @media (max-height: 1350px) {
        .height-100 {
            max-height: 1250px;
            overflow: auto;
        }
    }

    @media (max-height: 1325px) {
        .height-100 {
            max-height: 1225px;
            overflow: auto;
        }
    }

    @media (max-height: 1300px) {
        .height-100 {
            max-height: 1200px;
            overflow: auto;
        }
    }

    @media (max-height: 1275px) {
        .height-100 {
            max-height: 1175px;
            overflow: auto;
        }
    }

    @media (max-height: 1250px) {
        .height-100 {
            max-height: 1150px;
            overflow: auto;
        }
    }

    @media (max-height: 1225px) {
        .height-100 {
            max-height: 1125px;
            overflow: auto;
        }
    }

    @media (max-height: 1200px) {
        .height-100 {
            max-height: 1100px;
            overflow: auto;
        }
    }

    @media (max-height: 1175px) {
        .height-100 {
            max-height: 1075px;
            overflow: auto;
        }
    }

    @media (max-height: 1150px) {
        .height-100 {
            max-height: 1050px;
            overflow: auto;
        }
    }

    @media (max-height: 1125px) {
        .height-100 {
            max-height: 1025px;
            overflow: auto;
        }
    }

    @media (max-height: 1100px) {
        .height-100 {
            max-height: 1000px;
            overflow: auto;
        }
    }

    @media (max-height: 1075px) {
        .height-100 {
            max-height: 975px;
            overflow: auto;
        }
    }

    @media (max-height: 1050px) {
        .height-100 {
            max-height: 950px;
            overflow: auto;
        }
    }

    @media (max-height: 1025px) {
        .height-100 {
            max-height: 925px;
            overflow: auto;
        }
    }

    @media (max-height: 1000px) {
        .height-100 {
            max-height: 900px;
            overflow: auto;
        }
    }

    @media (max-height: 975px) {
        .height-100 {
            max-height: 875px;
            overflow: auto;
        }
    }

    @media (max-height: 950px) {
        .height-100 {
            max-height: 850px;
            overflow: auto;
        }
    }

    @media (max-height: 925px) {
        .height-100 {
            max-height: 825px;
            overflow: auto;
        }
    }

    @media (max-height: 900px) {
        .height-100 {
            max-height: 800px;
            overflow: auto;
        }
    }

    @media (max-height: 875px) {
        .height-100 {
            max-height: 775px;
            overflow: auto;
        }
    }

    @media (max-height: 850px) {
        .height-100 {
            max-height: 750px;
            overflow: auto;
        }
    }

    @media (max-height: 825px) {
        .height-100 {
            max-height: 725px;
            overflow: auto;
        }
    }

    @media (max-height: 800px) {
        .height-100 {
            max-height: 700px;
            overflow: auto;
        }
    }

    @media (max-height: 775px) {
        .height-100 {
            max-height: 675px;
            overflow: auto;
        }
    }

    @media (max-height: 750px) {
        .height-100 {
            max-height: 650px;
            overflow: auto;
        }
    }

    @media (max-height: 725px) {
        .height-100 {
            max-height: 625px;
            overflow: auto;
        }
    }

    @media (max-height: 700px) {
        .height-100 {
            max-height: 600px;
            overflow: auto;
        }
    }

    @media (max-height: 675px) {
        .height-100 {
            max-height: 575px;
            overflow: auto;
        }
    }

    @media (max-height: 650px) {
        .height-100 {
            max-height: 550px;
            overflow: auto;
        }
    }

    @media (max-height: 625px) {
        .height-100 {
            max-height: 525px;
            overflow: auto;
        }
    }

    @media (max-height: 600px) {
        .height-100 {
            max-height: 500px;
            overflow: auto;
        }
    }

    @media (max-height: 575px) {
        .height-100 {
            max-height: 475px;
            overflow: auto;
        }
    }

    @media (max-height: 550px) {
        .height-100 {
            max-height: 450px;
            overflow: auto;
        }
    }

    @media (max-height: 525px) {
        .height-100 {
            max-height: 425px;
            overflow: auto;
        }
    }

    @media (max-height: 500px) {
        .height-100 {
            max-height: 400px;
            overflow: auto;
        }
    }

    @media (max-height: 475px) {
        .height-100 {
            max-height: 375px;
            overflow: auto;
        }
    }

    @media (max-height: 450px) {
        .height-100 {
            max-height: 350px;
            overflow: auto;
        }
    }

    @media (max-height: 425px) {
        .height-100 {
            max-height: 325px;
            overflow: auto;
        }
    }

    @media (max-height: 400px) {
        .height-100 {
            max-height: 300px;
            overflow: auto;
        }
    }

    @media (max-height: 375px) {
        .height-100 {
            max-height: 275px;
            overflow: auto;
        }
    }

    @media (max-height: 350px) {
        .height-100 {
            max-height: 250px;
            overflow: auto;
        }
    }

    @media (max-height: 325px) {
        .height-100 {
            max-height: 225px;
            overflow: auto;
        }
    }

    @media (max-height: 300px) {
        .height-100 {
            max-height: 200px;
            overflow: auto;
        }
    }

    @media (max-height: 275px) {
        .height-100 {
            max-height: 175px;
            overflow: auto;
        }
    }

    @media (max-height: 250px) {
        .height-100 {
            max-height: 150px;
            overflow: auto;
        }
    }

    @media (max-height: 225px) {
        .height-100 {
            max-height: 125px;
            overflow: auto;
        }
    }

    @media (max-height: 200px) {
        .height-100 {
            max-height: 100px;
            overflow: auto;
        }
    }

    @media (max-height: 175px) {
        .height-100 {
            max-height: 75px;
            overflow: auto;
        }
    }

    @media (max-height: 150px) {
        .height-100 {
            max-height: 50px;
            overflow: auto;
        }
    }

    @media (max-height: 125px) {
        .height-100 {
            max-height: 25px;
            overflow: auto;
        }
    }

    @media (max-height: 100px) {
        .height-100 {
            max-height: 0px;
            overflow: auto;
        }
    }

    @media (max-height: 75px) {
        .height-100 {
            max-height: -25px;
            overflow: auto;
        }
    }

    @media (max-height: 50px) {
        .height-100 {
            max-height: -50px;
            overflow: auto;
        }
    }

    @media (max-height: 25px) {
        .height-100 {
            max-height: -75px;
            overflow: auto;
        }
    }

    @media (max-height: 1400px) {
        .height-75 {
            max-height: 1325px;
            overflow: auto;
        }
    }

    @media (max-height: 1375px) {
        .height-75 {
            max-height: 1300px;
            overflow: auto;
        }
    }

    @media (max-height: 1350px) {
        .height-75 {
            max-height: 1275px;
            overflow: auto;
        }
    }

    @media (max-height: 1325px) {
        .height-75 {
            max-height: 1250px;
            overflow: auto;
        }
    }

    @media (max-height: 1300px) {
        .height-75 {
            max-height: 1225px;
            overflow: auto;
        }
    }

    @media (max-height: 1275px) {
        .height-75 {
            max-height: 1200px;
            overflow: auto;
        }
    }

    @media (max-height: 1250px) {
        .height-75 {
            max-height: 1175px;
            overflow: auto;
        }
    }

    @media (max-height: 1225px) {
        .height-75 {
            max-height: 1150px;
            overflow: auto;
        }
    }

    @media (max-height: 1200px) {
        .height-75 {
            max-height: 1125px;
            overflow: auto;
        }
    }

    @media (max-height: 1175px) {
        .height-75 {
            max-height: 1100px;
            overflow: auto;
        }
    }

    @media (max-height: 1150px) {
        .height-75 {
            max-height: 1075px;
            overflow: auto;
        }
    }

    @media (max-height: 1125px) {
        .height-75 {
            max-height: 1050px;
            overflow: auto;
        }
    }

    @media (max-height: 1100px) {
        .height-75 {
            max-height: 1025px;
            overflow: auto;
        }
    }

    @media (max-height: 1075px) {
        .height-75 {
            max-height: 1000px;
            overflow: auto;
        }
    }

    @media (max-height: 1050px) {
        .height-75 {
            max-height: 975px;
            overflow: auto;
        }
    }

    @media (max-height: 1025px) {
        .height-75 {
            max-height: 950px;
            overflow: auto;
        }
    }

    @media (max-height: 1000px) {
        .height-75 {
            max-height: 925px;
            overflow: auto;
        }
    }

    @media (max-height: 975px) {
        .height-75 {
            max-height: 900px;
            overflow: auto;
        }
    }

    @media (max-height: 950px) {
        .height-75 {
            max-height: 875px;
            overflow: auto;
        }
    }

    @media (max-height: 925px) {
        .height-75 {
            max-height: 850px;
            overflow: auto;
        }
    }

    @media (max-height: 900px) {
        .height-75 {
            max-height: 825px;
            overflow: auto;
        }
    }

    @media (max-height: 875px) {
        .height-75 {
            max-height: 800px;
            overflow: auto;
        }
    }

    @media (max-height: 850px) {
        .height-75 {
            max-height: 775px;
            overflow: auto;
        }
    }

    @media (max-height: 825px) {
        .height-75 {
            max-height: 750px;
            overflow: auto;
        }
    }

    @media (max-height: 800px) {
        .height-75 {
            max-height: 725px;
            overflow: auto;
        }
    }

    @media (max-height: 775px) {
        .height-75 {
            max-height: 700px;
            overflow: auto;
        }
    }

    @media (max-height: 750px) {
        .height-75 {
            max-height: 675px;
            overflow: auto;
        }
    }

    @media (max-height: 725px) {
        .height-75 {
            max-height: 650px;
            overflow: auto;
        }
    }

    @media (max-height: 700px) {
        .height-75 {
            max-height: 625px;
            overflow: auto;
        }
    }

    @media (max-height: 675px) {
        .height-75 {
            max-height: 600px;
            overflow: auto;
        }
    }

    @media (max-height: 650px) {
        .height-75 {
            max-height: 575px;
            overflow: auto;
        }
    }

    @media (max-height: 625px) {
        .height-75 {
            max-height: 550px;
            overflow: auto;
        }
    }

    @media (max-height: 600px) {
        .height-75 {
            max-height: 525px;
            overflow: auto;
        }
    }

    @media (max-height: 575px) {
        .height-75 {
            max-height: 500px;
            overflow: auto;
        }
    }

    @media (max-height: 550px) {
        .height-75 {
            max-height: 475px;
            overflow: auto;
        }
    }

    @media (max-height: 525px) {
        .height-75 {
            max-height: 450px;
            overflow: auto;
        }
    }

    @media (max-height: 500px) {
        .height-75 {
            max-height: 425px;
            overflow: auto;
        }
    }

    @media (max-height: 475px) {
        .height-75 {
            max-height: 400px;
            overflow: auto;
        }
    }

    @media (max-height: 450px) {
        .height-75 {
            max-height: 375px;
            overflow: auto;
        }
    }

    @media (max-height: 425px) {
        .height-75 {
            max-height: 350px;
            overflow: auto;
        }
    }

    @media (max-height: 400px) {
        .height-75 {
            max-height: 325px;
            overflow: auto;
        }
    }

    @media (max-height: 375px) {
        .height-75 {
            max-height: 300px;
            overflow: auto;
        }
    }

    @media (max-height: 350px) {
        .height-75 {
            max-height: 275px;
            overflow: auto;
        }
    }

    @media (max-height: 325px) {
        .height-75 {
            max-height: 250px;
            overflow: auto;
        }
    }

    @media (max-height: 300px) {
        .height-75 {
            max-height: 225px;
            overflow: auto;
        }
    }

    @media (max-height: 275px) {
        .height-75 {
            max-height: 200px;
            overflow: auto;
        }
    }

    @media (max-height: 250px) {
        .height-75 {
            max-height: 175px;
            overflow: auto;
        }
    }

    @media (max-height: 225px) {
        .height-75 {
            max-height: 150px;
            overflow: auto;
        }
    }

    @media (max-height: 200px) {
        .height-75 {
            max-height: 125px;
            overflow: auto;
        }
    }

    @media (max-height: 175px) {
        .height-75 {
            max-height: 100px;
            overflow: auto;
        }
    }

    @media (max-height: 150px) {
        .height-75 {
            max-height: 75px;
            overflow: auto;
        }
    }

    @media (max-height: 125px) {
        .height-75 {
            max-height: 50px;
            overflow: auto;
        }
    }

    @media (max-height: 100px) {
        .height-75 {
            max-height: 25px;
            overflow: auto;
        }
    }

    @media (max-height: 75px) {
        .height-75 {
            max-height: 0px;
            overflow: auto;
        }
    }

    @media (max-height: 50px) {
        .height-75 {
            max-height: -25px;
            overflow: auto;
        }
    }

    @media (max-height: 25px) {
        .height-75 {
            max-height: -50px;
            overflow: auto;
        }
    }

    @media (max-height: 1400px) {
        .height-50 {
            max-height: 1350px;
            overflow: auto;
        }
    }

    @media (max-height: 1375px) {
        .height-50 {
            max-height: 1325px;
            overflow: auto;
        }
    }

    @media (max-height: 1350px) {
        .height-50 {
            max-height: 1300px;
            overflow: auto;
        }
    }

    @media (max-height: 1325px) {
        .height-50 {
            max-height: 1275px;
            overflow: auto;
        }
    }

    @media (max-height: 1300px) {
        .height-50 {
            max-height: 1250px;
            overflow: auto;
        }
    }

    @media (max-height: 1275px) {
        .height-50 {
            max-height: 1225px;
            overflow: auto;
        }
    }

    @media (max-height: 1250px) {
        .height-50 {
            max-height: 1200px;
            overflow: auto;
        }
    }

    @media (max-height: 1225px) {
        .height-50 {
            max-height: 1175px;
            overflow: auto;
        }
    }

    @media (max-height: 1200px) {
        .height-50 {
            max-height: 1150px;
            overflow: auto;
        }
    }

    @media (max-height: 1175px) {
        .height-50 {
            max-height: 1125px;
            overflow: auto;
        }
    }

    @media (max-height: 1150px) {
        .height-50 {
            max-height: 1100px;
            overflow: auto;
        }
    }

    @media (max-height: 1125px) {
        .height-50 {
            max-height: 1075px;
            overflow: auto;
        }
    }

    @media (max-height: 1100px) {
        .height-50 {
            max-height: 1050px;
            overflow: auto;
        }
    }

    @media (max-height: 1075px) {
        .height-50 {
            max-height: 1025px;
            overflow: auto;
        }
    }

    @media (max-height: 1050px) {
        .height-50 {
            max-height: 1000px;
            overflow: auto;
        }
    }

    @media (max-height: 1025px) {
        .height-50 {
            max-height: 975px;
            overflow: auto;
        }
    }

    @media (max-height: 1000px) {
        .height-50 {
            max-height: 950px;
            overflow: auto;
        }
    }

    @media (max-height: 975px) {
        .height-50 {
            max-height: 925px;
            overflow: auto;
        }
    }

    @media (max-height: 950px) {
        .height-50 {
            max-height: 900px;
            overflow: auto;
        }
    }

    @media (max-height: 925px) {
        .height-50 {
            max-height: 875px;
            overflow: auto;
        }
    }

    @media (max-height: 900px) {
        .height-50 {
            max-height: 850px;
            overflow: auto;
        }
    }

    @media (max-height: 875px) {
        .height-50 {
            max-height: 825px;
            overflow: auto;
        }
    }

    @media (max-height: 850px) {
        .height-50 {
            max-height: 800px;
            overflow: auto;
        }
    }

    @media (max-height: 825px) {
        .height-50 {
            max-height: 775px;
            overflow: auto;
        }
    }

    @media (max-height: 800px) {
        .height-50 {
            max-height: 750px;
            overflow: auto;
        }
    }

    @media (max-height: 775px) {
        .height-50 {
            max-height: 725px;
            overflow: auto;
        }
    }

    @media (max-height: 750px) {
        .height-50 {
            max-height: 700px;
            overflow: auto;
        }
    }

    @media (max-height: 725px) {
        .height-50 {
            max-height: 675px;
            overflow: auto;
        }
    }

    @media (max-height: 700px) {
        .height-50 {
            max-height: 650px;
            overflow: auto;
        }
    }

    @media (max-height: 675px) {
        .height-50 {
            max-height: 625px;
            overflow: auto;
        }
    }

    @media (max-height: 650px) {
        .height-50 {
            max-height: 600px;
            overflow: auto;
        }
    }

    @media (max-height: 625px) {
        .height-50 {
            max-height: 575px;
            overflow: auto;
        }
    }

    @media (max-height: 600px) {
        .height-50 {
            max-height: 550px;
            overflow: auto;
        }
    }

    @media (max-height: 575px) {
        .height-50 {
            max-height: 525px;
            overflow: auto;
        }
    }

    @media (max-height: 550px) {
        .height-50 {
            max-height: 500px;
            overflow: auto;
        }
    }

    @media (max-height: 525px) {
        .height-50 {
            max-height: 475px;
            overflow: auto;
        }
    }

    @media (max-height: 500px) {
        .height-50 {
            max-height: 450px;
            overflow: auto;
        }
    }

    @media (max-height: 475px) {
        .height-50 {
            max-height: 425px;
            overflow: auto;
        }
    }

    @media (max-height: 450px) {
        .height-50 {
            max-height: 400px;
            overflow: auto;
        }
    }

    @media (max-height: 425px) {
        .height-50 {
            max-height: 375px;
            overflow: auto;
        }
    }

    @media (max-height: 400px) {
        .height-50 {
            max-height: 350px;
            overflow: auto;
        }
    }

    @media (max-height: 375px) {
        .height-50 {
            max-height: 325px;
            overflow: auto;
        }
    }

    @media (max-height: 350px) {
        .height-50 {
            max-height: 300px;
            overflow: auto;
        }
    }

    @media (max-height: 325px) {
        .height-50 {
            max-height: 275px;
            overflow: auto;
        }
    }

    @media (max-height: 300px) {
        .height-50 {
            max-height: 250px;
            overflow: auto;
        }
    }

    @media (max-height: 275px) {
        .height-50 {
            max-height: 225px;
            overflow: auto;
        }
    }

    @media (max-height: 250px) {
        .height-50 {
            max-height: 200px;
            overflow: auto;
        }
    }

    @media (max-height: 225px) {
        .height-50 {
            max-height: 175px;
            overflow: auto;
        }
    }

    @media (max-height: 200px) {
        .height-50 {
            max-height: 150px;
            overflow: auto;
        }
    }

    @media (max-height: 175px) {
        .height-50 {
            max-height: 125px;
            overflow: auto;
        }
    }

    @media (max-height: 150px) {
        .height-50 {
            max-height: 100px;
            overflow: auto;
        }
    }

    @media (max-height: 125px) {
        .height-50 {
            max-height: 75px;
            overflow: auto;
        }
    }

    @media (max-height: 100px) {
        .height-50 {
            max-height: 50px;
            overflow: auto;
        }
    }

    @media (max-height: 75px) {
        .height-50 {
            max-height: 25px;
            overflow: auto;
        }
    }

    @media (max-height: 50px) {
        .height-50 {
            max-height: 0px;
            overflow: auto;
        }
    }

    @media (max-height: 25px) {
        .height-50 {
            max-height: -25px;
            overflow: auto;
        }
    }

    @media (max-height: 1400px) {
        .height-250 {
            max-height: 1150px;
            overflow: auto;
        }
    }

    @media (max-height: 1375px) {
        .height-250 {
            max-height: 1125px;
            overflow: auto;
        }
    }

    @media (max-height: 1350px) {
        .height-250 {
            max-height: 1100px;
            overflow: auto;
        }
    }

    @media (max-height: 1325px) {
        .height-250 {
            max-height: 1075px;
            overflow: auto;
        }
    }

    @media (max-height: 1300px) {
        .height-250 {
            max-height: 1050px;
            overflow: auto;
        }
    }

    @media (max-height: 1275px) {
        .height-250 {
            max-height: 1025px;
            overflow: auto;
        }
    }

    @media (max-height: 1250px) {
        .height-250 {
            max-height: 1000px;
            overflow: auto;
        }
    }

    @media (max-height: 1225px) {
        .height-250 {
            max-height: 975px;
            overflow: auto;
        }
    }

    @media (max-height: 1200px) {
        .height-250 {
            max-height: 950px;
            overflow: auto;
        }
    }

    @media (max-height: 1175px) {
        .height-250 {
            max-height: 925px;
            overflow: auto;
        }
    }

    @media (max-height: 1150px) {
        .height-250 {
            max-height: 900px;
            overflow: auto;
        }
    }

    @media (max-height: 1125px) {
        .height-250 {
            max-height: 875px;
            overflow: auto;
        }
    }

    @media (max-height: 1100px) {
        .height-250 {
            max-height: 850px;
            overflow: auto;
        }
    }

    @media (max-height: 1075px) {
        .height-250 {
            max-height: 825px;
            overflow: auto;
        }
    }

    @media (max-height: 1050px) {
        .height-250 {
            max-height: 800px;
            overflow: auto;
        }
    }

    @media (max-height: 1025px) {
        .height-250 {
            max-height: 775px;
            overflow: auto;
        }
    }

    @media (max-height: 1000px) {
        .height-250 {
            max-height: 750px;
            overflow: auto;
        }
    }

    @media (max-height: 975px) {
        .height-250 {
            max-height: 725px;
            overflow: auto;
        }
    }

    @media (max-height: 950px) {
        .height-250 {
            max-height: 700px;
            overflow: auto;
        }
    }

    @media (max-height: 925px) {
        .height-250 {
            max-height: 675px;
            overflow: auto;
        }
    }

    @media (max-height: 900px) {
        .height-250 {
            max-height: 650px;
            overflow: auto;
        }
    }

    @media (max-height: 875px) {
        .height-250 {
            max-height: 625px;
            overflow: auto;
        }
    }

    @media (max-height: 850px) {
        .height-250 {
            max-height: 600px;
            overflow: auto;
        }
    }

    @media (max-height: 825px) {
        .height-250 {
            max-height: 575px;
            overflow: auto;
        }
    }

    @media (max-height: 800px) {
        .height-250 {
            max-height: 550px;
            overflow: auto;
        }
    }

    @media (max-height: 775px) {
        .height-250 {
            max-height: 525px;
            overflow: auto;
        }
    }

    @media (max-height: 750px) {
        .height-250 {
            max-height: 500px;
            overflow: auto;
        }
    }

    @media (max-height: 725px) {
        .height-250 {
            max-height: 475px;
            overflow: auto;
        }
    }

    @media (max-height: 700px) {
        .height-250 {
            max-height: 450px;
            overflow: auto;
        }
    }

    @media (max-height: 675px) {
        .height-250 {
            max-height: 425px;
            overflow: auto;
        }
    }

    @media (max-height: 650px) {
        .height-250 {
            max-height: 400px;
            overflow: auto;
        }
    }

    @media (max-height: 625px) {
        .height-250 {
            max-height: 375px;
            overflow: auto;
        }
    }

    @media (max-height: 600px) {
        .height-250 {
            max-height: 350px;
            overflow: auto;
        }
    }

    @media (max-height: 575px) {
        .height-250 {
            max-height: 325px;
            overflow: auto;
        }
    }

    @media (max-height: 550px) {
        .height-250 {
            max-height: 300px;
            overflow: auto;
        }
    }

    @media (max-height: 525px) {
        .height-250 {
            max-height: 275px;
            overflow: auto;
        }
    }

    @media (max-height: 500px) {
        .height-250 {
            max-height: 250px;
            overflow: auto;
        }
    }

    @media (max-height: 475px) {
        .height-250 {
            max-height: 225px;
            overflow: auto;
        }
    }

    @media (max-height: 450px) {
        .height-250 {
            max-height: 200px;
            overflow: auto;
        }
    }

    @media (max-height: 425px) {
        .height-250 {
            max-height: 175px;
            overflow: auto;
        }
    }

    @media (max-height: 400px) {
        .height-250 {
            max-height: 150px;
            overflow: auto;
        }
    }

    @media (max-height: 375px) {
        .height-250 {
            max-height: 125px;
            overflow: auto;
        }
    }

    @media (max-height: 350px) {
        .height-250 {
            max-height: 100px;
            overflow: auto;
        }
    }

    @media (max-height: 325px) {
        .height-250 {
            max-height: 75px;
            overflow: auto;
        }
    }

    @media (max-height: 300px) {
        .height-250 {
            max-height: 50px;
            overflow: auto;
        }
    }

    @media (max-height: 275px) {
        .height-250 {
            max-height: 25px;
            overflow: auto;
        }
    }

    @media (max-height: 250px) {
        .height-250 {
            max-height: 0px;
            overflow: auto;
        }
    }

    @media (max-height: 225px) {
        .height-250 {
            max-height: -25px;
            overflow: auto;
        }
    }

    @media (max-height: 200px) {
        .height-250 {
            max-height: -50px;
            overflow: auto;
        }
    }

    @media (max-height: 175px) {
        .height-250 {
            max-height: -75px;
            overflow: auto;
        }
    }

    @media (max-height: 150px) {
        .height-250 {
            max-height: -100px;
            overflow: auto;
        }
    }

    @media (max-height: 125px) {
        .height-250 {
            max-height: -125px;
            overflow: auto;
        }
    }

    @media (max-height: 100px) {
        .height-250 {
            max-height: -150px;
            overflow: auto;
        }
    }

    @media (max-height: 75px) {
        .height-250 {
            max-height: -175px;
            overflow: auto;
        }
    }

    @media (max-height: 50px) {
        .height-250 {
            max-height: -200px;
            overflow: auto;
        }
    }

    @media (max-height: 25px) {
        .height-250 {
            max-height: -225px;
            overflow: auto;
        }
    }

    .wide-modal {
        min-width: 940px;
    }

    @media (max-width: 1000px) {
        .wide-modal {
            min-width: 940px;
        }
    }

    @media (max-width: 825px) {
        .wide-modal {
            min-width: 800px;
        }
    }

    @media (max-width: 800px) {
        .wide-modal {
            min-width: 100%;
            margin: 0 auto;
        }
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 50%;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
        overflow-x: auto;
        max-height: 98vh;
    }

    .max-w-25 {
        max-width: 25vw;
    }

    @media screen and (min-width:750px) {
        .modal-container {
            width: 600px;
        }
    }

    @media screen and (max-width:749px) {
        .modal-container {
            width: 100vw;
        }
    }

    .modal-header h3 {
        margin-top: 0;
    }

    .modal-header,
    .modal-footer {
        padding: 0;
    }

    .modal-body {
        margin: .5em 0;
    }

    .modal-default-button {
        float: right;
    }

    .span-button {
        cursor: pointer;
        margin: .3em .2em;
    }

    /*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .flex-container {
        display: flex;
    }

    .flex-space-between {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        align-content: center;
    }

    .flex-stretch-vertical {
        align-items: stretch;
        display: flex;
        flex-grow: 1;
    }

    .review-file-admin-panel,
    .review-file-admin-sign-panel {
        width: max(300px, min(500px, 35%));
        min-width: 300px;
        overflow: auto;
    }

    .flex-grow {
        flex-grow: 1;
    }

    .review-file-admin-sign-panel {}


    .flex-between {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .pdf-viewer {
        width: 800px !important;
        height: 1150px !important;
        border: 1px solid #aaaaaa;
    }

    .canvas-container {
        width: 800px !important;
        height: 1150px !important;
        margin: 16px;
    }

    .radio-toolbar {
        padding-top: 10px;
    }

    .radio-toolbar input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    .radio-toolbar label {
        display: inline-block;
        background-color: #ddd;
        padding: 6px 12px;
        font-family: sans-serif, Arial;
        font-size: 14px;
        border: 1px solid #444;
        border-radius: 4px;
    }

    .radio-toolbar input[type="radio"]:checked+label {
        background-color: #bfb;
        border-color: #4c4;
    }

    .radio-toolbar label:hover {
        background-color: #dfd;
        cursor: pointer;
    }

    .packet-download-all a, .packet-download-all a:hover{
        color:#888888;
    }

    .uploaded-buttons {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .uploaded-buttons div {
        text-align: center;
    }

    .uploaded-buttons i.fa,
    .uploaded-buttons i.fad,
    .uploaded-buttons i.fal,
    .uploaded-buttons i.fas {
        padding-left: 0;
        padding-right: 0;
    }

    .file-list .file-description {
        color: #555;
        font-size: .8em;
    }

    .file-list {}

    .file-list .file {
        display: flex;
        border: 1px solid #aaa;
        border-radius: 4px;
        margin: .5em;
        padding: .5em 1em;
        align-items: center;
        justify-content: space-between;
    }

    .file-list .file:hover {
        box-shadow: 2px 2px 5px #aaa;
    }


   